$sloganFontSize: 135px;
$bigSize: 60px;
$textBorder: 2px 0 0 white, -2px 0 0 white, 0 2px 0 white, 0 -2px 0 white, 1px 1px white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white;

.borderText {
  text-shadow: $textBorder;
}
.fontSizeBig {
  font-size: $bigSize;
}
.fontSizeMedium {
  font-size: 30px;
}

.astro-text{
  font-size: 34px !important;
}

.astro2-text{
  font-size: 30px !important;
  font-weight: normal !important;
  opacity: 0.75 !important;
}

.text-justify{
  text-align: center !important;

}
.loginPage {
  background-image: url('../../assets/bg/bgLogin.png')  ;
  background-size:cover !important;
  background-repeat: no-repeat;
  background-position: center;
  overflow: auto;
  min-height: 100vh !important
}
@media screen and (max-width: 795px){
  .LoginSection{
    padding-bottom: 5% !important;
  }

}
