.recrute-section1 {
  background: linear-gradient(180deg, #19002a, #272eaf, #9d32e4);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 0;
  overflow: hidden;

  #r-mobile {
    @media screen and (max-width: 700px) {
      background-image: url("../../assets/img/inscription/rmobile.png");
      position: absolute;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      top: 14%;
      width: 119%;
    }
  }

  .recrute-overlay1 {
    background-image: url("../../assets/img/inscription/recrute-overlay1.png");
    position: absolute;
    width: 100%;
    height: 111%;
    background-size: cover;
    background-repeat: no-repeat;
    top: 10%;
    @media screen and (max-width: 1336px) {
      width: 100%;
      height: 100%;
      top: 11%;
    }
    @media screen and (max-width: 1280px) {
      width: 100%;
      height: 106%;
      top: 11%;
    }
  }

  .title-recrute1 {
    opacity: 1;
    letter-spacing: 0.98px;
    padding-top: 8%;
    @media screen and (max-width: 700px) {
      padding-top: 34%;
    }
  }

  .description-recrute2 {
    opacity: 1;
    letter-spacing: 1.01px;
    padding-bottom: 3%;
  }

  .text-recrute2 {
    opacity: 1;
    letter-spacing: 0px;
    padding-bottom: 8%;
  }

  .padding-button {
    @media screen and (max-width: 700px) {
      padding-top: 20%;
    }
  }
}

.recrute-section2 {
  background: linear-gradient(180deg, #9d32e4, #1b2389);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: -1;
  @media screen and (max-width: 1600px) {
    min-height: 140vh;
  }
  @media screen and (max-width: 1336px) {
    min-height: 110vh;
  }

  .recrute-fusil {
    top: 58%;
    left: 7%;
    width: 39%;
    @media screen and (max-width: 1336px) {
      top: 55%;
      left: 5%;
      width: 39%;
    }
    @media screen and (max-width: 1280px) {
      top: 52%;
      left: 2%;
      width: 39%;
    }
    @media screen and (max-width: 700px) {
      top: 19%;
      height: 30%;
      left: 26%;
    }
    @media screen and (max-width: 575px) {
      top: 19%;
      height: 24%;
      left: 26%;
    }
    @media screen and (max-width: 410px) {
      top: 19%;
      height: 20%;
      left: 26%;
    }
    @media screen and (max-width: 321px) {
      top: 19%;
      height: 16%;
      left: 26%;
    }
  }

  .recrute-cosmonaute {
    left: 15%;
    top: 62%;
    width: 30%;
    height: 77%;
    position: absolute;
    @media screen and (max-width: 1600px) {
      left: 13%;
      top: 55%;
      width: 30%;
      height: 55%;
    }
    @media screen and (max-width: 1336px) {
      left: 13%;
      top: 50%;
      width: 30%;
      height: 63%;
    }
    @media screen and (max-width: 1280px) {
      left: 10%;
      top: 50%;
      width: 30%;
      height: 54%;
    }
    @media screen and (max-width: 1080px) {
      left: 10%;
      top: 50%;
      width: 30%;
      height: 46%;
    }
    @media screen and (max-width: 900px) {
      left: 10%;
      top: 50%;
      width: 30%;
      height: 37%;
    }
    @media screen and (max-width: 700px) {
      top: 23%;
      height: 25%;
      left: 34%;
    }
    @media screen and (max-width: 575px) {
      top: 23%;
      height: 18%;
      left: 34%;
    }
    @media screen and (max-width: 410px) {
      top: 19%;
      height: 18%;
      left: 34%;
    }
    @media screen and (max-width: 321px) {
      top: 20%;
      height: 13%;
      left: 34%;
    }
  }

  .title-recrute2 {
    opacity: 1;
    padding-top: 7%;
    float: right;
    padding-right: 8%;
    @media screen and (max-width: 700px) {
      line-height: 143% !important;
      text-align: center;
      letter-spacing: 3.04px;
    }
  }

  .description-recrute2 {
    color: #ffffff;
    opacity: 1;
    @media screen and (max-width: 700px) {
      line-height: 131%;
    }

    .text2 {
      float: right;
      padding-right: 8%;
      @media screen and (max-width: 700px) {
        float: left;
        padding-left: 5%;
      }
    }

    .padding-mobile {
      @media screen and (max-width: 700px) {
        padding-top: 63%;
      }
    }
  }

  .text-recrute2 {
    color: #ffffff;
    opacity: 1;
    float: right !important;
    padding-right: 11% !important;

    .text2 {
      padding-top: 4rem;
      @media screen and (max-width: 1336px) {
        padding-top: 1rem;
      }
      @media screen and (max-width: 1280px) {
        padding-top: 0rem;
      }

    }
    @media screen and (max-width: 1280px) {
      transform: translate(646px, 138px);
    }
    @media screen and (max-width: 1024px) {
      transform:unset;
    }
    @media screen and (max-width: 700px) {
      font-size: 24px !important;
      padding-top: 8%;
      padding-left: 6%;
    }
  }
}

.recrute-section3 {
  background: linear-gradient(180deg, #1b2389, #121232, #121232);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: -2;
  padding-bottom: 8%;

  .title-recrute3 {
    margin-left: 9%;
    padding-top: 23%;
    padding-bottom: 1.5%;
    opacity: 1;
    letter-spacing: 0px;
    color: #2df8c0;
    @media screen and (max-width: 700px) {
      padding-bottom: 64%;
    }
  }

  .description-recrute3 {
    margin-left: 9%;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    @media screen and (max-width: 1150px) {
      font-size: 22px !important;
      line-height: 28px;
      margin-left: 9%;
    }
    @media screen and (max-width: 920px) {
      font-size: 17.5px !important;
      line-height: 28px;
      margin-left: 9%;
    }
    @media screen and (max-width: 700px) {
      font-size: 24px !important;
      line-height: 28px;
      margin-left: 6%;
    }
  }

  .recrute-diamant1 {
    background-image: url("../../assets/img/inscription/diamant.png");
    position: absolute;
    width: 7%;
    height: 7%;
    background-size: cover;
    background-repeat: no-repeat;
    top: 32%;
    left: 5%;
    @media screen and (max-width: 700px) {
      visibility: hidden;
      display: none;
    }
  }

  .recrute-diamant2 {
    background-image: url("../../assets/img/inscription/diamant.png");
    position: absolute;
    width: 1%;
    height: 29%;
    background-size: cover;
    background-repeat: no-repeat;
    top: 27%;
    left: 5%;
    @media screen and (max-width: 700px) {
      visibility: hidden;
      display: none;
    }
  }

  .recrute-cd {
    background-image: url("../../assets/img/inscription/recrute-cd.png");
    position: absolute;
    width: 32.4%;
    height: 54.7%;
    background-size: contain;
    background-repeat: no-repeat;
    top: 33%;
    left: 61%;
    @media screen and (max-width: 700px) {
      left: 22%;
      top: 27%;
      width: 54%;
    }
  }

  .margin-top-recrute {
    @media screen and (max-width: 700px) {
      margin-top: 23% !important;
    }
  }
}

.recrute-section4 {
  background: linear-gradient(180deg, #121232, #1b2389);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: -3;
  @media screen and (max-width: 1920px) {
    min-height: 80vh;
  }

  .recrute-title {
    opacity: 1;
    letter-spacing: 0px;
    color: #329de4;
    margin-bottom: 1rem;
    margin-left: 49%;
    padding-top: 18%;
    @media screen and (max-width: 700px) {
      padding-bottom: 79%;
      padding-left: 6%;
      margin-left: 0%;
    }
  }

  .recrute-description {
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-left: 49%;
    @media screen and (max-width: 700px) {
      font-size: 24px !important;
      line-height: 28px !important;
      margin-left: 3%;
    }
  }

  .recrute-screen1 {
    background-image: url("../../assets/img/inscription/screen1.png");
    position: absolute;
    width: 100%;
    height: 65%;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0%;
    left: 0%;
    mix-blend-mode: screen;
    z-index: 3;
  }

  .recrute-overlay4 {
    width: 35%;
    background-repeat: no-repeat;
    top: 130px;
    margin-left: 8%;
    z-index: 1;
    @media screen and (max-width: 700px) {
      left: 9%;
      width: 68%;
      top: 181px;
    }
  }
}

.recrute-section5 {
  background: linear-gradient(180deg, #1b2389, #1b2389, #236aab);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: -4;

  .recrute-title {
    opacity: 1;
    letter-spacing: 0px;
    color: #ffc937;
    margin-bottom: 0rem;
    margin-left: 10%;
    padding-top: 12%;
    @media screen and (max-width: 700px) {
      margin-left: 3%;
      padding-bottom: 72%;
    }
  }

  .recrute-description {
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-left: 10%;
    @media screen and (max-width: 700px) {
      font-size: 24px !important;
      line-height: 28px !important;
      margin-left: 3%;
    }
    @media screen and (max-width: 620px) {
      transform: translate(0, 30%);

    }
  }

  .recrute-overlay5 {
    position: absolute;
    width: 35%;
    background-size: contain;
    background-repeat: no-repeat;
    top: 15%;
    margin-left: 60%;
    z-index: 999999999999999999999999999999;
    @media screen and (max-width: 700px) {
      width: 55%;
      left: -38%;
      top: 22%;
    }
  }
}

#redirect-section{
  padding: 220px 0;
}

.recrute-section6 {
  height: 100%;
  min-height: 169vh;
  position: relative;
  z-index: 1;

  .recrute-title1 {
    opacity: 1;
    letter-spacing: 0px;
    color: #2df8c0;
    position: absolute;
    text-align: center !important;
    @media screen and (max-width: 700px) {
      background: linear-gradient(180deg, #236aab, #236aab);
      font-size: 40px !important;
      line-height: 174% !important;
    }
    @media screen and (max-width: 620px) {
      height: 46%;
    }
    @media screen and (max-width: 425px) {
      padding-bottom: 15px;
      padding-left: 2%;
      padding-right: 2%;
      height: 53%;
    }
    @media screen and (max-width: 375px) {

      height: 63%;
    }
  }

  .recrute-description1 {
    opacity: 1;
    letter-spacing: 0px;
    color: #ffffff;
    position: absolute;
  }

  .recrute-overlay6 {
    background-image: url("../../assets/img/inscription/recrute-overlay6.png");
    position: absolute;
    width: 100%;
    height: 147%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    @media screen and (max-width: 1920px) {
      height: 148% !important;
    }
    @media screen and (max-width: 1600px) {
      height: 202% !important;
    }
    @media screen and (max-width: 1366px) {
      height: 173% !important;
    }
    @media screen and (max-width: 1280px) {
      height: 162% !important;
    }
    @media screen and (max-width: 700px) {
      height: 54% !important;
      width: 100% !important;
      top: 45%;
    }
    @media screen and (max-width: 425px) {
      top: 53%;
    }
    @media screen and (max-width: 375px) {
      top: 63%;
    }
  }

  .div1 {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      display: inline;
    }

    .box1 {
      background: #2df8c0 0% 0% no-repeat padding-box;
      border: 1px solid #2df8c0 !important;
      opacity: 1;
      text-align: center;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0px;
      color: #211f85;
      text-transform: uppercase;
      opacity: 1;
      font-family: "roboto-bold";
      padding: 13px 30px 13px 30px;
      width: 200px;
    }

    .text-recrute {
      font-family: "Roboto-regular";
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: lowercase;
      opacity: 1;
      margin-left: 28px;
      margin-right: 28px;
    }

    .box2 {
      border: 1px solid #2df8c0 !important;
      background: #2df8c0 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      text-align: center;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0px;
      color: #211f85;
      text-transform: uppercase;
      opacity: 1;
      font-family: "roboto-bold";
      padding: 13px 30px 13px 30px;
      width: 200px;
    }
  }
  @media screen and (max-width: 1600px) {
    min-height: 100vh;
  }
}

.recrute-section7 {
  background: linear-gradient(180deg, #151b47, #151b47);
  height: 100%;
  position: relative;
  z-index: 0;
  padding: 30% 10% 10% 10%;

  .infini {
    margin-left: 35%;
  }

  .margin-infinity {
    margin-bottom: 5rem !important;
    @media screen and (max-width: 1280px) {
      margin-bottom: 0% !important;
    }
    @media screen and (max-width: 1024px) {
      margin-bottom: 2% !important;
    }

  }

  .contact {
    margin-bottom: 5%;
    @media screen and (max-width: 1280px) {
      margin-bottom: 0% !important;
    }
  }

  @media screen and (max-width: 1920px) {
    padding: 40% 10% 10% 10% !important;
  }
  @media screen and (max-width: 1600px) {
    padding: 41% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 41% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1366px) {
    padding: 36% 10% 10% 10% !important;
  }
  @media screen and (max-width: 1280px) {
    padding: 31% 13% 10% 10% !important;

  }
  @media screen and (max-width: 700px) {
    margin-top: -100px;
  }
  @media screen and (max-width: 425px) {
    margin-top: 0;
  }
  @media screen and (max-width: 1024px) {
    padding: 40% 13% 10% 10% !important;

  }
  @media screen and (max-width: 768px) {
    padding: 45% 13% 10% 10% !important;

  }
}

.recrute-section7 > .row {
  z-index: 2;
}

.recrute-stars11 {
  background-image: url("../../assets/img/inscription/recrute-stars11.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 65%;
  @media screen and (max-width: 700px) {
    height: 52%;
    top: 50%;
    width: 100%;
  }
}

.recrute-image {
  background-image: url("../../assets/img/inscription/recute-image.png");
  mix-blend-mode: screen;
  position: absolute;
  width: 150%;
  height: 88%;
  background-size: contain;
  background-repeat: no-repeat;
  top: 12%;
  left: 49%;
}

.recrute-image1 {
  background-image: url("../../assets/img/inscription/recute-image.png");
  mix-blend-mode: screen;
  position: absolute;
  width: 150%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0%;
  left: -2%;
}

.recrute-image2 {
  background-image: url("../../assets/img/inscription/recute-image.png");
  mix-blend-mode: screen;
  position: absolute;
  width: 150%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0%;
  left: 49%;
  @media screen and (max-width: 1600px) {
    top: 0%;
  }
  @media screen and (max-width: 1366px) {
    top: 0%;
  }
  @media screen and (max-width: 1280px) {
    top: 0%;
  }
}

.recrute-screen2 {
  background-image: url("../../assets/img/inscription/recrute-screen2.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  top: -40%;
  left: 0%;
  mix-blend-mode: screen;
  z-index: 0;
  @media screen and (max-width: 700px) {
    height: 28% !important;
    top: -13%;
  }
}

.recrute-screen3 {
  background-image: url("../../assets/img/inscription/screen1.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  top: -40%;
  left: 0%;
  mix-blend-mode: screen;
  z-index: 0;
}

.notice a {
  color: #fff;
  text-decoration: none;
}

.notice a:hover {
  cursor: pointer;
  color: #fff;
  opacity: 0.75;
}



