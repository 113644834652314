.deleteModal {
    color: #0f1447;
    p {
      font-size: 36px !important;
      text-align: center !important;
      width: 100% !important;
      @media screen and (max-width: 1600px) {
        font-size: 32px !important;
      }
      @media screen and (max-width: 1366px) {
        font-size: 27px !important;
      }
      @media screen and (max-width: 1024px) {
        font-size: 22px !important;
      }
      @media screen and (max-width: 900px) {
        font-size: 17px !important;
      }
    }
  }