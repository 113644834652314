.propose-interview {
    font-family: "Roboto-medium" !important;
    font-size: 15px !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    color: #0f67d9 !important;
    text-align: left !important;
    text-decoration: underline;
}

.remove-mission {
    background-color: #b1b1b1 !important;
    border-color: #b1b1b1 !important;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 15px !important;
    color: #ffffff !important;
}

.remove-mission:hover {
    background-color: #ec6161 !important;
}

.mission-recrute {
    font-family: "Roboto-medium" !important;
    font-size: 14px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    background: #ffffff !important;
    box-shadow: none !important;
    @media screen and (max-width: 1600px) {
        font-size: 13px !important;
    }
    @media screen and (max-width: 1440px) {
        font-size: 12px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 11px !important;
    }
    @media screen and (max-width: 980px) {
        font-size: 10px !important;
    }
}
.mission-recrute:disabled {
    border-color: #b1b1b1 !important;
    color: #b1b1b1 !important;
    background-color: #fbfbfb !important;
}
.mission-recrute.green,
.mission-recrute.green:focus {
    border-color: #2b9e7f;
    color: #2b9e7f;
}
.mission-recrute.green:hover {
    border-color: #2b9e7f;
    background-color: #2b9e7f !important;
    color: #ffffff;
}

.mission-recrute.red,
.mission-recrute.red:focus {
    border-color: #ec6161;
    color: #ec6161;
}
.mission-recrute.red:hover {
    border-color: #ec6161;
    background-color: #ec6161 !important;
    color: #ffffff;
}