.title-mission {
    font-family: "Roboto-bold" !important;
    font-size: 18px !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    color: #0f1447 !important;
    text-align: left !important;
    @media screen and (max-width: 1600px) {
        font-size: 16px !important;
    }
    @media screen and (max-width: 1366px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 1280px) {
        font-size: 13px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 12px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 10px !important;
    }
}

.title-candidat-name {
    font-family: "Roboto-regular" !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0px !important;
    color: #0f1447 !important;
    text-align: left !important;
    margin-left: 5px;
    @media screen and (max-width: 1600px) {
        font-size: 13px !important;
        line-height: 15px !important;
    }
    @media screen and (max-width: 1366px) {
        font-size: 12px !important;
        line-height: 14px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 11px !important;
        line-height: 13px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 10px !important;
        line-height: 11px !important;
    }
}
.table-content td:first-child > div {
    @media screen and (max-width: 1280px) {
        min-width: 30px;
    }
}
@media screen and (max-width: 1024px) {
    .text-tag {
        font-size: 10px !important;
    }
    .from-to > p {
        width: 70px;
    }
    .create-date > p {
        width: 60px;
    }
}
