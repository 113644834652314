.title-detail {
  font-family: "Roboto-bold" !important;
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
  color: #0f1447 !important;
  text-align: left !important;
}
.arrow-back-mission {
  font-family: "Roboto-light" !important;
  border: solid 2px rgba(125, 127, 155, 0.2);
  border-radius: 50%;
  width: 41px !important;
  height: 41px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #7d7f9b;
}
.periode {
  font-family: "Roboto-regular" !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  color: #b1b1b1 !important;
  text-align: left !important;
}
.propose-interview {
  font-family: "Roboto-medium" !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  color: #0f67d9 !important;
  text-align: left !important;
  text-decoration: underline;
}
.margin-bottom-skills {
  margin-bottom: -1.5rem !important;
}
.margin-bottom-doc {
  margin-bottom: 3rem !important;
  @media screen and (max-width: 1280px) {
    margin-top: 3rem !important;
    margin-bottom: 5rem !important;
  }
}
.margin-top-doc {
    margin-top: 1.25rem !important;
    @media screen and (max-width: 1280px) {
      margin-top: 3rem !important;
    }
  }