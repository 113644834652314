.pg-apropos-sec1 {
  background: linear-gradient(180deg, #19002a, #272eaf, #7c4ce4);
  height: 100vh;
  position: relative;
  z-index: 0;
  overflow: hidden;
  .padding-top-title {
    @media screen and (max-width:700px) {
      padding-top: 30% !important;
    }
  }
}
.pg-apropos-sec2 {
  background: linear-gradient(180deg, #7c4ce4, #5734b4, #24186f);
  height: 100vh;
  position: relative;
  z-index: -2;
  .padding-bottom-text {
    @media screen and (max-width:700px) {
      padding-bottom: 80% !important;
    }

  }
}
.pg-apropos-sec3 {
  background: linear-gradient(180deg, #24186f, #1b2389);
  height: 100%;
  position: relative;
  z-index: -3;
  padding-bottom: 5%;
  min-height: 100vh;
  .padding-bottom-text{
    @media screen and (max-width:900px) {
      padding-bottom: 94% !important;
    }
  }
}
.pg-apropos-sec4 {
  background: url("../../assets/img/inscription/Rectangle1067.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  z-index: 999999999999;
  min-height: 100vh;
  .line-heigth-text{
    @media screen and (max-width:900px) {
      line-height: 160% !important; 
    }
  }
}
.pg-apropos-sec5 {
  background: linear-gradient(180deg, #151b47, #151b47);
  height: 100%;
  position: relative;
  z-index: 0;
  padding: 18% 10% 12% 10%;
  .infini {
    margin-left: 35%;
  }
  .contact {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 1600px) {
    padding: 15% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 10% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1366px) {
    padding: 20% 10% 10% 10% !important;
  }
  @media screen and (max-width: 1280px) {
    padding: 18% 13% 10% 10% !important;
  }
  @media screen and (max-width: 1050px) {
    padding: 20% 13% 10% 10% !important;
  }
  @media screen and (max-width: 900px) {
    padding: 10% 13% 10% 10% !important;
  }
  @media screen and (max-width: 768px) {
    padding: 15% 13% 10% 10% !important;
  }
}
.overlay-propos1 {
  background-image: url("../../assets/img/inscription/bgsection-props1.png");
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 94%;
  left: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 10%;
  @media screen and (max-width: 1280px) {
    width: 100%;
    height: 105%;
  }
  @media screen and (max-width: 1366px) {
    width: 100%;
    height: 108%;
  }
  }
.overlay-propos2 {
  background-image: url("../../assets/img/inscription/overlay-propos2.png");
  position: absolute;
  z-index: -1;
  width: 10%;
  height: 19%;
  left: -2%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 12%;
  @media screen and (max-width: 1366px) {
    height: 21%;
  
  }
  @media screen and (max-width: 1280px) {
    height: 20% !important;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
  
}
.overlay-propos {
  background-image: url("../../assets/img/inscription/overlay-propos.png");
  position: absolute;
  z-index: 0;
  width: 40%;
  height: 70%;
  left: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 30%;
  @media screen and (max-width:1600px) {
    top: 29%;
    width: 39%;
  }
  @media screen and (max-width:1280px) {
    top: 26%;
    width: 38%;
    left: 62%;
  }
  @media screen and (max-width:1366px) {
    top: 26%;
    width: 35%;
    left: 65%;
  }
  @media screen and (max-width:900px) {
    background-size: contain;
    top: 18%;
    width: 89%;
    left: 9%;
  }
}
.overlay {
  background-image: url("../../assets/img/inscription/overlay.png");
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 70%;
  left: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0%;
}
.overlay1 {
  background-image: url("../../assets/img/inscription/overlay.png");
  position: absolute;
  z-index: 1111;
  width: 100%;
  height: 70%;
  left: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  top: -30%;
  @media screen and (max-width:900px) {
    height: 27%;
    top: 20%;
  }
}
.overlay-galaxies {
  background-image: url("../../assets/img/inscription/overlaygalaxies.png");
  width: 100%;
  height: 80%;
  position: absolute;
  top: -18%;
  background-size: cover;
  background-repeat: no-repeat 0% 0%;
  mix-blend-mode: screen;
}
.overlay-galaxie {
  background-image: url("../../assets/img/inscription/overlaygalaxies.png");
  width: 100%;
  height: 17%;
  position: absolute;
  top: 80%;
  background-size: cover;
  background-repeat: no-repeat 0% 0%;
  mix-blend-mode: screen;
}
.overlay-props4 {
  background-image: url("../../assets/img/inscription/overlay-props4.png");
  position: absolute;
  z-index: -3;
  width: 100%;
  height: 108%;
  left: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 17%;
  @media screen and (max-width:1366px) {
    height: 118%;
  }
  @media screen and (max-width:900px) {
    height: 32%;
    top: 70%;
  }
}
.overlay-cercle {
  background-image: url("../../assets/img/inscription/overlay-cercle4.png");
  position: absolute;
  z-index: -1;
  width: 8%;
  height: 14%;
  left: 5%;
  background-size: contain;
  background-repeat: no-repeat;
  top: -3%;
  @media screen and (max-width:900px) {
    display: none;
  }
}
.diamant {
  background-image: url("../../assets/img/inscription/diamant.png");
  position: absolute;
  z-index: -1;
  width: 5%;
  height: 6%;
  left: 88%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 10%;
  @media screen and (max-width:900px) {
    display: none;
  }
}
.diamant1 {
  background-image: url("../../assets/img/inscription/diamant.png");
  position: absolute;
  z-index: -1;
  width: 5%;
  height: 5%;
  left: 84%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 26%;
  @media screen and (max-width:900px) {
    display: none;
  }
}
.overlay-sections5 {
  background-image: url("../../assets/img/inscription/overlay-propos5.png");
  position: absolute;
  z-index: -1;
  width: 80%;
  height: 76%;
  left: 2%;
  background-size: contain;
  background-repeat: no-repeat;
  top: 5%;
  @media screen and (max-width:900px) {
    left: 11%;
    top: 24%;
  }
}
.overlay-sections6 {
  background-image: url("../../assets/img/inscription/overlay-propos6.png");
  position: absolute;
  z-index: -1;
  width: 22%;
  height: 30%;
  left: 82%;
  background-size: contain;
  background-repeat: no-repeat;
  top: 62%;
}
.etoile-propos {
  background-image: url("../../assets/img/inscription/etoile-props.png");
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0%;
}
.title-text-section2 {
  line-height: 140px;
  margin-left: 6%;
  @media screen and (max-width: 1280px) {
    line-height: 89px;
    margin-left: 6%;
  }
  @media screen and (max-width: 1336px) {
    line-height: 89px;
    margin-left: 6%;
  }
}
.title-description-propos2 {
  line-height: 50px;
  margin-left: 6%;
  @media screen and (max-width: 1280px) {
    line-height: 40px;
    margin-left: 6%;
  }
  @media screen and (max-width: 1336px) {
    line-height: 40px;
    margin-left: 6%;
  }
}
.text-propos3 {
  line-height: 126px;
  margin-left: 50%;
  @media screen and (max-width:1280px) {
    line-height: 82px;
  }
  @media screen and (max-width:900px) {
    line-height: 113% !important;
    margin-left: 5% !important;
  }
}
.description-propos3 {
  line-height: 58px;
  margin-left: 50%;
  width: 35%;
  margin-top: 1%;
  @media screen and (max-width:1280px) {
    width: 41%;
  }
  @media screen and (max-width:900px) {
    margin-left: 5% !important;
    line-height: 121% !important;
    font-size: 24px !important;
    width: 90% !important;
  }
}
.overlay-planet {
  background-image: url("../../assets/img/inscription/overlay-propos6.png");
  position: absolute;
  z-index: -1;
  width: 11%;
  height: 20%;
  left: -2%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 72%;
  @media screen and (max-width:1280px) {
    width: 10%;
  }
  @media screen and (max-width:900px) {
    display: none   ;
  }
}
.notice a{
  color: #fff;
  text-decoration: none;
}
.notice a:hover{
  cursor: pointer;
  color: #fff;
  opacity: 0.75;
}