.homePage {
  background: url("../../assets/img/bg.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}
#section1 {
  padding: 10% 10% 8% 10%;
  .it-Description {
    padding: 1% 9% 2% 9% !important;
    color: #ffff !important;
    opacity: 0.75;
    @media screen and (max-width:700px) {
      padding: 3% 0% 10% 0% !important;
      opacity: 1;
      font-size: 16px !important;
    }
  }
  .fontSize-mobile-38 {
    @media screen and (max-width:700px) {
      font-size: 38px !important;
    }
  }
  .margin-botton-mobile {
    @media screen and (max-width:700px) {
      margin-bottom: -3rem !important;
    }
  }
  .margin-top-text {
    @media screen and (max-width:700px) {
      margin-top: 32% !important;
    }
  }
}
#section2 {
  padding: 8% 10% 8% 10%;
  .cristals {
    z-index: 0;
    left: -1%;
    width: 100%;
    top: 34%;
  }
  .bubble {
    left: -45%;
    width: 130%;
    top: -18%;
  }
  .p-recruter {
    top: 70%;
    left: 40%;
    @media screen and (max-width:700px) {
      display: none;
    }
  }
  .font-size-description {
    @media screen and (max-width:700px) {
      font-size: 22px !important;
    }
  }
  .btn-recrute {
    border-color: #ffc937 !important;
    background-color: #ffc937 !important;
    color: #211f85 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: unset !important;
    -moz-border-radius: unset !important;
    border-radius: unset !important;
    padding: 10px !important;
    font-size: 23px !important;
    line-height: 26px !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 1600px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 1440px) {
      font-size: 18px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px !important;
    }
    @media screen and (max-width: 980px) {
      font-size: 14px !important;
      margin-top: 47% !important;
    }
    @media screen and (max-width: 466px) {
      margin-left: 30%;
    }
    @media screen and (max-width: 321px) {
      padding: 5px !important;
    }
  }
  .col-5 {
    flex: 0 0 auto;
    width: 29.666667% !important;
  }
  .col-7 {
    flex: 0 0 auto;
    width: 54.333333% !important;
  }
}
#section3 {
  padding: 8% 10% 8% 10%;
}
#section4 {
  padding: 15% 10% 20% 10%;
  .fusee {
    top: 19%;
    right: 10%;
    width: 30%;
    @media screen and (max-width: 1600px) {
      width: 32%;
    }
    @media screen and (max-width: 1440px) {
      width: 29%;
    }
    @media screen and (max-width: 1024px) {
      width: 25%;
    }
    @media screen and (max-width: 980px) {
      display: none;
      width: 23%;
    }
  }
  .missions-title {
    z-index: 1;
    margin-left: -4% !important;
    @media screen and (max-width: 1600px) {
      margin-left: -10% !important;
    }
    @media screen and (max-width: 1440px) {
      margin-left: -15% !important;
    }
    @media screen and (max-width: 900px) {
      text-align: center !important;
      margin-left: 10% !important;
    }
    .first {
      margin-left: 10% !important;
      @media screen and (max-width: 1600px) {
        margin-left: 20% !important;
      }
      @media screen and (max-width: 1440px) {
        margin-left: 30% !important;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 40% !important;
      }
      @media screen and (max-width: 980px) {
        margin-left: 0% !important;
      }
    }
    .second {
      margin-left: 0% !important;
      @media screen and (max-width: 1600px) {
        margin-left: 10% !important;
      }
      @media screen and (max-width: 1440px) {
        margin-left: 20% !important;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 30% !important;
      }
      @media screen and (max-width: 980px) {
        margin-left: 0% !important;
      }
    }
    .third {
      margin-left: 10% !important;
      @media screen and (max-width: 1600px) {
        margin-left: 20% !important;
      }
      @media screen and (max-width: 1440px) {
        margin-left: 30% !important;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 40% !important;
      }
      @media screen and (max-width: 900px) {
        margin-left: 0% !important;
      }
    }
    .forth {
      margin-left: 15% !important;
      @media screen and (max-width: 1600px) {
        margin-left: 20% !important;
      }
      @media screen and (max-width: 1440px) {
        margin-left: 30% !important;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 40% !important;
      }
      @media screen and (max-width: 980px) {
        margin-left: 0% !important;
      }
    }
    .fifth {
      margin-left: 20% !important;
      margin-bottom: 5% !important;
      @media screen and (max-width: 1600px) {
        margin-left: 30% !important;
      }
      @media screen and (max-width: 1440px) {
        margin-left: 40% !important;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 50% !important;
      }
      @media screen and (max-width: 980px) {
        margin-left: 0% !important;
      }
    }
  }
  .btn-mission {
    margin-left: 30% !important;
    border-color: #0af2b2 !important;
    background-color: #0af2b2 !important;
    color: #211f85 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: unset !important;
    -moz-border-radius: unset !important;
    border-radius: unset !important;
    padding: 13px 30px 12px 30px !important;
    font-size: 20px !important;
    line-height: 26px !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 1600px) {
      margin-left: 40% !important;
      font-size: 20px !important;
      padding: 11px 25px 11px 25px !important;
    }
    @media screen and (max-width: 1440px) {
      margin-left: 50% !important;
      font-size: 18px !important;
      padding: 11px 23px 11px 23px !important;
    }
    @media screen and (max-width: 1024px) {
      margin-left: 60% !important;
      font-size: 16px !important;
      padding: 10px 20px 10px 20px !important;
    }
    @media screen and (max-width: 980px) {
      margin-left: 70% !important;
      font-size: 14px !important;
      padding: 8px 15px 8px 15px !important;
      @media screen and (max-width:900px) {
        margin-left: 7% !important;
        font-size: 16px !important;
        padding: 10px 27px 8px 24px !important;
        margin-top: 8% !important;
      }
    }
  }
}
#section5 {
  padding: 15% 10% 15% 10%;
  .unique {
    margin-left: 35%;
    @media screen and (max-width:900px) {
      margin-left: 42% !important;
      font-size: 50px;
    }
  }
  .team {
    margin-left: 36%;
    @media screen and (max-width:900px) {
      margin-left: 42%;
      font-size: 16px !important;
      margin-bottom: 0rem !important;
    }
  }
  .planet {
    left: -5%;
    top: 45%;
    width: 25%;
    @media screen and (max-width:900px) {
      display: none;
    }
  }
  .cosmo {
    z-index: 1;
    left: 15%;
    top: 45%;
    -webkit-transform: rotate(15deg) !important;
    -moz-transform: rotate(15deg) !important;
    -ms-transform: rotate(15deg) !important;
    -o-transform: rotate(15deg) !important;
    transform: rotate(15deg) !important;
    img {
      width: 85%;
    }
    @media screen and (max-width: 1600px) {
      left: 12%;
      top: 45%;
      img {
        width: 70%;
      }
    }
    @media screen and (max-width: 1440px) {
      left: 10%;
      top: 45%;
      img {
        width: 70%;
      }
    }
    @media screen and (max-width: 1024px) {
      left: 8%;
      top: 45%;
      img {
        width: 60%;
      }
    }
    @media screen and (max-width: 980px) {
      left: 4%;
      top: 49%;
      transform: rotate(12deg) !important;
      img {
        width: 80%;
      }
    }
  }
  .font-size-title {
    @media screen and (max-width:900px) {
      font-size: 50px !important;
      margin-right: 14% !important;
    }
  }
}
#section6 {
  padding: 15% 10% 0 10%;
  .space-between {
    padding-bottom: 5%;
  }
}
#section7 {
  padding: 15% 15% 2% 15%;
  @media screen and (max-width: 1600px) {
    padding: 5% 15% 2% 15%;
  }
  .client {
    padding-top: 120px;
    @media screen and (max-width:900px) {
      padding-top: 0px !important;
    }
  }
  .first {
    padding-bottom: 3%;
  }
  .second {
    padding-bottom: 3%;
  }
  .last {
    padding-bottom: 5%;
  }
  .btn-recrute {
    border-color: #ffc937 !important;
    background-color: #ffc937 !important;
    color: #211f85 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: unset !important;
    -moz-border-radius: unset !important;
    border-radius: unset !important;
    padding: 15px 35px 13px 35px !important;
    font-size: 23px !important;
    line-height: 26px !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 1600px) {
      font-size: 20px !important;
      padding: 13px 30px 11px 30px !important;
    }
    @media screen and (max-width: 1440px) {
      font-size: 18px !important;
      padding: 11px 45px 11px 45px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px !important;
      padding: 9px 40px 9px 40px !important;
    }
    @media screen and (max-width: 980px) {
      font-size: 14px !important;
      padding: 8px 35px 8px 35px !important;
    }
  }
  .row {
    @media screen and (max-width:900px) {
      flex-direction: column-reverse;
    }
  }
  .col-9 {
    @media screen and (max-width:900px) {
      width: 100% !important;
    }
  }
  .button-section {
    @media screen and (max-width:900px) {
      text-align: center;
      margin-top: 21% !important;
    }
  }
  .fontSize-24 {
    font-size: 30px !important;
    line-height: 40px !important;
    letter-spacing: 0px !important;
    @media screen and (max-width:900px) {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  }
}
#section8 {
  padding: 10% 10% 0 10%;
  @media screen and (max-width: 900px) {
    padding: 2% 10% 74% 10%;
  }
  .w-planet {
    width: 95% !important;
    @media screen and (max-width:900px) {
      width: 42% !important;
    }
  }
  .text-size {
    @media screen and (max-width:900px) {
      font-size: 50px !important;
      margin-bottom: 8% !important;
      line-height: 86px !important;
    }
  }
  .display-text {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
#section9 {
  padding: 40% 10% 20% 10%;
  @media screen and (max-width: 1600px) {
    padding: 44% 10% 20% 10% !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 45% 10% 15% 10% !important;
  }
  .first {
    margin-left: 28%;
    @media screen and (max-width:900px) {
      margin-left: 10% !important;
    }
  }
  .second {
    margin-left: 50%;
    @media screen and (max-width:900px) {
      margin-left: 0% !important;
    }
  }
  .third {
    margin-left: 43%;
    margin-bottom: 2%;
    @media screen and (max-width:900px) {
      margin-left: 17% !important;
    }
  }
  .last {
    margin-left: 48%;
    margin-bottom: 2%;
    @media screen and (max-width:900px) {
      margin-left: 0% !important;
    }
  }
  .btn-recrute {
    margin-left: 48%;
    border-color: #ffc937 !important;
    background-color: #ffc937 !important;
    color: #211f85 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: unset !important;
    -moz-border-radius: unset !important;
    border-radius: unset !important;
    padding: 15px 35px 13px 35px !important;
    font-size: 23px !important;
    line-height: 26px !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 1600px) {
      font-size: 20px !important;
      padding: 13px 30px 11px 30px !important;
    }
    @media screen and (max-width: 1440px) {
      font-size: 18px !important;
      padding: 11px 45px 11px 45px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px !important;
      padding: 9px 40px 9px 40px !important;
    }
    @media screen and (max-width: 980px) {
      font-size: 14px !important;
      padding: 12px 10px 12px 10px !important;
      margin-left: 18% !important;
    }
  }
  .display-text {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
#section10 {
  padding: 25% 10% 5% 10%;
  .infini {
    margin-left: 30%;
  }
  .contact {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 1600px) {
    padding: 25% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 25% 10% 5% 10% !important;
  }
  .margin-contact-mobile {
    margin-bottom: 3rem !important;
    opacity: 1;
    @media screen and (max-width: 900px) {
      margin-bottom: 0rem !important;
    }
  }
  .line-height-row {
    @media screen and (max-width: 900px) {
      line-height: 58px !important;
    }
  }
}
/* spacing */
.text-spacing-1 {
  margin-bottom: -1rem !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: -1rem !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: -1rem !important;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0rem !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 0rem !important;
  }
}
.text-spacing-2 {
  margin-bottom: -2rem !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: -2rem !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: -1rem !important;
  }
  @media screen and (max-width: 1280px) {
    margin-bottom: -1rem !important;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0rem !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 0rem !important;
  }
}
.text-spacing-3 {
  margin-bottom: -3rem !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: -2rem !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: -1rem !important;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0rem !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 0rem !important;
  }
}
.text-spacing-4 {
  margin-bottom: -4.5rem !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: -3rem !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: -1rem !important;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0rem !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 0rem !important;
  }
}
.text-spacing-5 {
  margin-bottom: -5rem !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: -3rem !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: -3rem !important;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: -2rem !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: -1rem !important;
  }
}
.text-spacing-6 {
  margin-bottom: -6rem !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: -5rem !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: -4rem !important;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: -3rem !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: -2rem !important;
  }
}
.text-spacing-7 {
  margin-bottom: -7rem !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: -5rem !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: -4rem !important;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: -3rem !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: -1rem !important;
  }
}
/* Colors */
.green-color {
  color: #2df8c0 !important;
}
.yellow-color {
  color: #ffd94a !important;
}
.purple-Color {
  color: #3a3ece !important;
}
/* Fonts Sizes */
.fontSize-260 {
  font-size: 260px !important;
  @media screen and (max-width: 1600px) {
    font-size: 220px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 180px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 140px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
}
.fontSize-178 {
  font-size: 178px !important;
  @media screen and (max-width: 1600px) {
    font-size: 130px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 100px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 70px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
}
.fontSize-160 {
  font-size: 160px !important;
  @media screen and (max-width: 1600px) {
    font-size: 120px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 90px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 45px !important;
  }
}
.fontSize-140 {
  font-size: 125px !important;
  @media screen and (max-width: 1600px) {
    font-size: 90px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 85px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 85px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 45px !important;
  }
}
.fontSize-145 {
  font-size: 145px !important;
  @media screen and (max-width: 1600px) {
    font-size: 115px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 95px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 85px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 70px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 40px !important;
  }
}
.fontSize-150 {
  font-size: 150px !important;
  @media screen and (max-width: 1600px) {
    font-size: 120px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 100px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 90px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 70px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
}
.fontSize-135 {
  font-size: 135px !important;
  @media screen and (max-width: 1600px) {
    font-size: 110px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 90px !important;
    line-height: 100px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
}
.fontSize-130 {
  font-size: 130px !important;
  @media screen and (max-width: 1600px) {
    font-size: 110px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 90px !important;
    line-height: 100px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 80px !important;
    line-height: 100px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 40px !important;
  }
}
.fontSize-125 {
  font-size: 125px !important;
  @media screen and (max-width: 1600px) {
    font-size: 90px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 75px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 40px !important;
  }
}
.fontSize-120 {
  font-size: 120px !important;
  @media screen and (max-width: 1600px) {
    font-size: 100px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 75px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
}
.fontSize-100 {
  font-size: 100px !important;
  line-height: 104px !important;
  @media screen and (max-width: 1600px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 24px !important;
    line-height: 57px !important;
  }
}
.fontSize-90 {
  font-size: 90px !important;
  @media screen and (max-width: 1600px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 30px !important;
    line-height: 30px !important;
  }
}
.fontSize-92 {
  font-size: 92px !important;
  @media screen and (max-width: 1600px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 30px !important;
  }
}
.fontSize-70 {
  font-size: 70px !important;
  @media screen and (max-width: 1600px) {
    font-size: 60px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 30px !important;
  }
}
.fontSize-63 {
  font-size: 63px !important;
  @media screen and (max-width: 1600px) {
    font-size: 55px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 45px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 35px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 25px !important;
  }
}
.fontSize-60 {
  font-size: 60px !important;
  @media screen and (max-width: 1600px) {
    font-size: 55px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 45px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 35px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 25px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 30px !important;
    line-height: 30px !important;
  }
}
.fontSize-55 {
  font-size: 55px !important;
  @media screen and (max-width: 1600px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 45px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 35px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 30px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 25px !important;
    line-height: 25px !important;
  }
}
.fontSize-45 {
  font-size: 45px !important;
  line-height: 56px !important;
  letter-spacing: 0px !important;
  @media screen and (max-width: 1600px) {
    font-size: 35px !important;
    line-height: 49px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 25px !important;
    line-height: 40px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 30px !important;
    line-height: 41px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 25px !important;
    line-height: 36px !important;
  }
}
.fontSize-50 {
  font-size: 50px !important;
  line-height: 45px !important;
  letter-spacing: 0px !important;
  @media screen and (max-width: 1600px) {
    font-size: 40px !important;
    line-height: 51px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 35px !important;
    line-height: 46px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 30px !important;
    line-height: 41px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 25px !important;
    line-height: 36px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 24px !important;
    line-height: 26px !important;
  }
}
.fontSize-40 {
  font-size: 40px !important;
  line-height: 50px !important;
  letter-spacing: 0px !important;
  @media screen and (max-width: 1600px) {
    font-size: 35px !important;
    line-height: 45px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 20px !important;
    line-height: 25px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 27px !important;
    line-height: 30px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 16px !important;
  }
}
.fontSize-35 {
  font-size: 35px !important;
  @media screen and (max-width: 1600px) {
    font-size: 30px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 25px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 15px !important;
  }
}
.fontSize-30 {
  font-size: 30px !important;
  line-height: 40px !important;
  letter-spacing: 0px !important;
  @media screen and (max-width: 1600px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 15px !important;
    line-height: 25px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 13px !important;
    line-height: 19px !important;
  }
}
.fontSize-25 {
  font-size: 25px !important;
  @media screen and (max-width: 1600px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 15px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 13px !important;
  }
}
.text-transparent {
  -webkit-text-stroke: 1px #fff;
  color: transparent;
}
.text-opacity5-transparent {
  -webkit-text-stroke: 1px #fff;
  opacity: 0.5 !important;
  color: transparent;
}
.text-opacity3-transparent {
  -webkit-text-stroke: 1px #fff;
  opacity: 0.3 !important;
  color: transparent;
}
.text-green-transparent {
  -webkit-text-stroke: 1px #2df8c0;
  color: transparent;
}
.text-yellow-transparent {
  -webkit-text-stroke: 1px #ffd94a;
  color: transparent;
}
.mode-Luminosity {
  mix-blend-mode: luminosity !important;
}
/* Animation */
.animate-3 {
  -webkit-animation: float 3s ease-in-out infinite;
  -moz-animation: float 3s ease-in-out infinite;
  -o-animation: float 3s ease-in-out infinite;
  animation: float 3s ease-in-out infinite;
}
.animate-4 {
  -webkit-animation: float 4s ease-in-out infinite;
  -moz-animation: float 4s ease-in-out infinite;
  -o-animation: float 4s ease-in-out infinite;
  animation: float 4s ease-in-out infinite;
}
.animate-5 {
  -webkit-animation: float 5s ease-in-out infinite;
  -moz-animation: float 5s ease-in-out infinite;
  -o-animation: float 5s ease-in-out infinite;
  animation: float 5s ease-in-out infinite;
}
.animate-6 {
  -webkit-animation: float 6s ease-in-out infinite;
  -moz-animation: float 6s ease-in-out infinite;
  -o-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
}
/* keys frames */
@-webkit-keyframes an {
  from {
    opacity: 0;
    -webkit-transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(500px) translate3d(0, 0, 0);
    transform: perspective(500px) translate3d(0, 0, 0);
  }
}
@-moz-keyframes an {
  from {
    opacity: 0;
    -moz-transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
  }
  to {
    opacity: 1;
    -moz-transform: perspective(500px) translate3d(0, 0, 0);
    transform: perspective(500px) translate3d(0, 0, 0);
  }
}
@-o-keyframes an {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
  }
  to {
    opacity: 1;
    transform: perspective(500px) translate3d(0, 0, 0);
  }
}
@keyframes an {
  from {
    opacity: 0;
    -webkit-transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    -moz-transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(500px) translate3d(0, 0, 0);
    -moz-transform: perspective(500px) translate3d(0, 0, 0);
    transform: perspective(500px) translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-moz-keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-o-keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  100% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }
}
@-moz-keyframes float {
  0% {
    -moz-transform: translatey(0px);
    transform: translatey(0px);
  }
  50% {
    -moz-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  100% {
    -moz-transform: translatey(0px);
    transform: translatey(0px);
  }
}
@-o-keyframes float {
  0% {
    -o-transform: translatey(0px);
    transform: translatey(0px);
  }
  50% {
    -o-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  100% {
    -o-transform: translatey(0px);
    transform: translatey(0px);
  }
}
@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
    -moz-transform: translatey(0px);
    -o-transform: translatey(0px);
    transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-20px);
    -moz-transform: translatey(-20px);
    -o-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  100% {
    -webkit-transform: translatey(0px);
    -moz-transform: translatey(0px);
    -o-transform: translatey(0px);
    transform: translatey(0px);
  }
}
.fontSize-20 {
  font-size: 20px !important;
}
.padding-proposed-profile {
  @media screen and (max-width: 1280px) {
    padding: 3% !important;
  }
}