
$bigSize:30px;
.form-section{
  border-radius: 20px !important;
}

.title{
  font-size: $bigSize !important;
  font-weight: bold !important;
}
.formGroup{
  color: #1C105D;
  font-size: 20px;
}

.LoginSubmitBtn{
  min-width: 200px !important;
  min-height: 56px !important;
  border-radius: 30px !important;


}
.validation-text{
  font-size: 13px ;

}
.loginInput{
  height: 50px !important;

}
.PasswordFormFooter {
  background-color: #E7E8E9 !important;
  border-radius: 0 0 20px 20px !important;
  height: 70px;
  border-top: 1px solid #E7E8E9 !important;
}
.form-label{
  color: #1C105D !important;
}

@media screen and (max-width: 440px ) {
  .form-section{
    padding: 8% 0 0 0 !important;
  }
  .form-section{
    margin-top: 40px !important;
  }
}

@media screen and (min-width: 440px ) {
  .form-section{
    padding: 8% 5% 0 5% !important;
  }
}

