#navScroll {
  justify-content: flex-end;
  a {
    color: white;
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    @media screen and (max-width: 1290px) {
      font-size: 13px !important;
    }
    @media screen and (max-width: 1055px) {
      margin-right: 5px !important;
      font-size: 10px !important;
    }
  }
}
.nav-Content {
  display: flex;
  width: 100%;
  padding: 0px 100px 0px 100px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef !important;
}
.header-Section {
  position: absolute !important;
  width: calc(100% - 120px);
  .nav-link {
    color: #ffffff !important;
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .nav-link:nth-child(1) {
    margin-right: 30px !important;
  }
  .nav-link:nth-child(2) {
    margin-right: 30px !important;
  }
}
.active-side {
  background: linear-gradient(90deg, rgb(35, 55, 140) 1%, rgb(35, 55, 140) 1%) !important;
  height: 65px !important;
}
.btn-NavBar {
  font-family: "Roboto-regular" !important;
  font-size: 17px !important;
  letter-spacing: 0px !important;
  color: #0f1447 !important;
  opacity: 1;
}
.header-Section.with-content {
  background: transparent !important;
}
.header-Section.no-content {
  background: url("../../assets/bg/bgHeader.png") no-repeat !important;
  background-size: cover !important;
}
.icon-navbar {
  font-size: 20px;
  font-weight: bold !important;
}
.color-black {
  color: #0f1447 !important;
}
.color-white {
  color: #ffffff !important;
}
.navbar-light .navbar-toggler-icon {
  @media screen and (max-width:700px) {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
}
