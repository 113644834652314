.astro {
  height: 190px;
  float: right;
}

#quizz{
  cursor: pointer;
}

#formulaire{
  cursor: pointer;
}

#number-cards{
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

#white-arrow{
  color: white;
}
.card.card-cosmonaute {
  border: 1px solid #434cb47a !important;
  border-radius: 14px !important;
  background-color: #0e10335c !important;
  height: 100%;
  width: 100%;
  //background-color:transparent !important;
  .card-header {
    padding: 1rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #2d2e84;
    border-radius: 14px 14px 0px 0px !important;
  }
}
.card.card-mission {
  border: 1px solid #434cb47a !important;
  border-radius: 14px !important;
  background-color: #0e10335c !important;
  //height: 50%;
  //width: 100%;
  background-color: transparent !important;
}
.card.boost_card {
  background: #0f67d9 !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
.card.upcom_intervi {
  background: #ffffff !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;
  color: #000000;
}
.card.specific_mission {
  background: #2c9e7f !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
.card.upcom_intervi1 {
  background: #ffffff !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;
  color: #000000;
}
.card.all_missions {
  background: #171154 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  opacity: 1;
}

.number {
  background: #ffffff;
  border-radius: 2px;
  opacity: 0.1;
  font-family: "Roboto-bold";
  color: #ffffff;
  font-size: 14px;
  opacity: 1;
}
.month {
  background: rgba(15, 103, 217, 0.1);
  border-radius: 2px;
  width: 60px !important;
  small {
    //padding: 10px;
    text-align: left;
    font-family: "Roboto-regular";
    font-size: 14px;
    letter-spacing: 0px;
    color: #0f67d9 !important;
    opacity: 1;
    line-height: 17px;
  }
}

.month-white {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  small {
    padding: 20px;
    text-align: left;
    font-family: "Roboto-regular";
    font-size: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    line-height: 17px;
  }
}

.IT_firstCard {
  width: -webkit-fill-available !important;
  padding: 42px 46px;
}
.intrviews-card {
  background: #ffffff !important;
  border: 1px solid #db636373 !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  //background-color: #ffffff !important;
}
.card-small {
  border: 1px solid #434cb4 !important;
  border-radius: 14px !important;
  padding: 20px;
}
.w-wbkit {
  width: -webkit-fill-available !important;
  padding-right: 20px !important;
}
.card-img-top {
  top: 145px;
  left: 691px;
  width: 327px;
  height: 200px;
  background: transparent url("../../../assets/img/astronaut.png") 0% 0%
    no-repeat padding-box !important;
  opacity: 1 !important;
}

.card-title {
  font-family: "Roboto-bold" !important;
  font-size: 30px !important;
  letter-spacing: 0px !important;
  line-height: 37px !important;
  color: #ffffff !important;
  @media screen and (max-width: 700px) {
    font-size: 24px !important;
    line-height: 29px !important;
  }
}

.text-number-cosmo {
  font-family: "Roboto-bold";
  font-size: 40px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 700px) {
    text-align: center !important;
  }
}
.text-cosmo {
  font-family: "Roboto-medium";
  font-size: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 700px) {
    text-align: center !important;
  }
}

.buttondashbord {
  width: 150px !important;
  height: 37px !important;
  background: #259879 !important;
  border-color: #259879 !important;
  border-radius: 30px !important;
  opacity: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.progress {
  height: 5px !important;
  //width: 439px !important;
}
.progress-bar {
  background-color: #27ae89 !important;
}

.col2 {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #db636373 !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  color: #000000;
}
.card_title {
  font-family: "Roboto-bold";
  color: #0f1447;
  padding-right: 20px;
}

.company {
  font-family: "roboto-regular";
  color: #0f1447 !important ;
  font-size: 16px !important;
  opacity: 1;
  letter-spacing: 0px;
}

.small_write2 {
  font-family: "Roboto-regular" !important;
  font-size: 13px !important;
  color: #070606 !important;
  opacity: 0.5 !important;
}
.upcom {
  text-align: left;
  font-family: "Roboto-medium";
  font-size: 16px;
  letter-spacing: 0px;
  color: #1d268c;
  opacity: 1;
}
.new-color-interviews {
  font-size: 18px !important;
  color: cornflowerblue !important;
}
.mission-text {
  text-align: left;
  font-family: "Roboto-bold";
  font-size: 26px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 13px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px !important;
  }
}

.text-header-cosmonaute {
  font-family: "Roboto-bold" !important;
  text-align: left;
  white-space: normal;
  font-size: 20px !important;
  letter-spacing: 0px !important;
  color: #0f1447 !important;
  margin-right: 5px;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 12px !important;
  }
}

.petit-text {
  text-align: left;
  font-family: "Roboto-regular";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.8;
  font-size: 16px;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 14px !important;
  }
}

.text-fill-form {
  text-align: left;
  font-size: 16px;
  font-family: "Robot-italic";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  i {
    margin-left: 15px;
    font-size: 11px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
  }
}
.icon-left-size {
  font-size: 11px !important;
}
.icon-mission-size {
  font-size: 59px !important;
}

.card_mission {
  text-align: left;
  font-family: "Roboto-bold";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 22px;
}
.magento-text {
  text-align: left;
  font-family: "Roboto-regular";
  letter-spacing: 0px;
  color: #2c9e7f;
  opacity: 1;
}
.astroo {
  width: 59px;
  height: 59px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-left: 10px;
  margin-right: 5px;
}

.code {
  background: #0f67d9 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}

.text-code {
  font-family: "Roboto-regular" !important;
  font-size: 12px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  opacity: 0.71 !important;
}
.text-test {
  font-family: "Roboto" !important;
  font-style: italic !important;
  font-size: 16px !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;
  i {
    margin-left: 10px !important;
    font-size: 11px !important;
  }
}

.text_test {
  font-family: "Roboto-regular" !important;
  font-size: 16px !important;
  opacity: 1;
  color: #000;
  i {
    padding-left: 23px !important;
    width: 14px !important;
    height: 11px !important;
    font-size: 13px;
    opacity: 1;
    @media screen and (max-width: 1280px) {
      padding-left: 10px !important;
    }
  }
}
.mt-13 {
  margin-top: 6px !important;
}

.text_agenda {
  font-family: "Roboto-medium" !important;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: #1d268c !important;
  opacity: 1;
}

.box {
  width: 20px;
  height: 20px;
  background: #d52bff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}
.box_1 {
  width: 20px;
  height: 20px;
  background: #4653dd 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.agenda {
  height: 290px;
  background: #171154 padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  opacity: 1;
  padding: 5px 30px !important;
}

.mb-45 {
  margin-bottom: 30px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}

.margin-left-text {
  margin-left: 30px !important;
}

.background-transparent {
  background-color: transparent !important;
  border: 1px solid #434cb4 !important;
  border-radius: 14px !important;
  opacity: 1 !important;
}
.link-decoration {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.current-date {
  margin-right: 8px !important ;
}
.current-date1 {
  margin-right: 6px !important;
}

.header-mission {
  border: 2px solid #2d2e84;
}

.text-md {
  display: flex !important;
  @media screen and (max-width: 1600px) {
    display: flex !important;
  }
  @media screen and (max-width: 1366px) {
    display: block !important;
  }
  @media screen and (max-width: 1280px) {
    display: block !important;
  }
  @media screen and (max-width: 900px) {
    display: flex !important;
  }
}
.tooltip-dashbord {
  transform: translate(360px, 1140px) !important;
  background-color: white;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #f6f3f3 !important;
}
@import url("https://cdn.syncfusion.com/ej2/material.css");

.cosmonaute-dashbord-text {
  @media screen and (max-width: 700px) {
    font-family: "Roboto-regular" !important;
    font-size: 18px !important;
    line-height: 22px !important;
    letter-spacing: 0px;
    color: rgb(255, 255, 255, 0.49);
  }
}

.d-inline-text {
  display: inline;
  @media screen and (max-width: 700px) {
    display: block !important;
  }
}
.table > :not(caption) > * > * {
  @media screen and (max-width: 700px) {
    padding: 0.5rem 0.2rem !important;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
}
.margin-top-block-mission {
  @media screen and (max-width: 700px) {
      margin-top: 6% !important;
  }
}

#main .row > div{
  @media screen and (max-width: 992px) {
    margin-bottom: 5%;
  }
}

.margin-top-mission-favorite {
  margin-top: 20px !important;
}