/* Modal Style Start */
.modal {
  overflow-y: hidden;
}
.modal-lg,
.modal-xl {
  max-width: 800px;
  @media screen and (max-width: 1600px) {
    max-width: 700 !important;
  }
  @media screen and (max-width: 1366px) {
    max-width: 730px !important;
  }
  @media screen and (max-width: 1024px) {
    max-width: 500px !important;
  }
  @media screen and (max-width: 900px) {
    max-width: 400px !important;
  }
  @media screen and (max-height: 550px) {
    max-width: 600px !important;
  }
}
.modal-content {
  padding: 5% 7% 5% 7% !important;
  -webkit-border-radius: 11px !important;
  -moz-border-radius: 11px !important;
  border-radius: 11px !important;
  @media screen and (max-width: 1366px) {
    padding: 3% 5% 3% 5% !important;
  }
}
.modal-header {
  border: none !important;
}
.modal-body {
  max-height: 600px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  @media screen and (max-width: 1600px) {
    max-height: 500px !important;
  }
  @media screen and (max-width: 1366px) {
    max-height: 400px !important;
  }
  @media screen and (max-height: 630px) {
    max-height: 250px !important;
  }
  @media screen and (max-height: 550px) {
    max-height: 200px !important;
  }
  @media screen and (max-width: 1024px) {
    max-height: 300px !important;
  }
  @media screen and (max-width: 900px) {
    max-height: 600px !important;
  }
}

.modal-title {
  font-family: "Roboto-bold" !important;
  font-size: 30px !important;
  letter-spacing: 0px !important;
  color: #0f1447 !important;
  text-align: left !important;
  @media screen and (max-width: 1366px) {
    font-size: 25px !important;
  }
}
.modal-footer {
  border: none !important;
}
.modal-dialog-scrollable .modal-content {
  overflow-y: scroll !important;
}
/* Modal Style End */
