.btn-action {
  font-family: "Roboto-regular" !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  @media screen and (max-width: 1600px) {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 10px !important;
    line-height: 15px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 11px !important;
    line-height: 13px !important;
  }
}
.btn-action.grey {
  color: #b1b1b1 !important;
}
.btn-action.red {
  color: #e94545 !important;
}
.box-d-flex-order {
  @media screen and (max-width: 1600px) {
    display: flex !important;
    justify-content: space-between !important;

    .w-md-100:first-child {
      float: right;
    }
    .w-md-100 {
      width: max-content !important;
    }
    .w-md-100:nth-child(2) {
      margin-top: 2rem;
      order: 3;
      width: 100% !important;
    }
    .w-md-100:nth-child(3) {
      order: 2;
    }
  }
}

#tag{
  display: flex;
  text-align: center;
  width:fit-content;
}