/* Content Fonts */
@font-face {
    font-family: "Roboto-bold";
    src: url("./assets/fonts/Roboto-Bold.eot"), url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-medium";
    src: url("./assets/fonts/Roboto-Medium.eot"), url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-light";
    src: url("./assets/fonts/Roboto-Light.eot"), url("./assets/fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-regular";
    src: url("./assets/fonts/Roboto-Regular.eot"), url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-black";
    src: url("./assets/fonts/Roboto-Black.eot"), url("./assets/fonts/Roboto-Black.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-condensed-bold";
    src: url("./assets/fonts/RobotoCondensed-Bold.ttf") format("truetype");
    // font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-condensed";
    src: url("./assets/fonts/RobotoCondensed-Light.ttf") format("truetype");
    // font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-meduim-italic";
    src: url("./assets/fonts/Roboto-Medium-Italic.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-italic";
    src: url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.roboto-condensed {
    font-family: "Roboto-condensed";
}

.roboto-meduim-italic {
    font-family: "Roboto-meduim-italic";
}

.roboto-condensed-bold {
    font-family: "Roboto-condensed-bold";
}

.roboto-black {
    font-family: "Roboto-black";
}

.roboto-light {
    font-family: "Roboto-light" !important;
}

.roboto-italic {
    font-family: "Roboto-italic";
}

.roboto-medium {
    font-family: "Roboto-medium";
}

.roboto-bold {
    font-family: "Roboto-bold";
}
