.period{
    display: flex;
    margin: 5px 0;
}

.period-box{
    display: flex;
    flex-wrap: wrap;
}

.text-camera {
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    color: #8c89ab !important;
}

.w-40 {
    width: 40px !important;
}

.w-90 {
    width: 90px !important;
}

.w-120 {
    width: 120px !important;
}

.w-183 {
    width: 183px !important;
}

.w-150 {
    width: 150px !important;
}

.w-250 {
    width: 250px !important;
}

.w-200 {
    width: 200px !important;
}

.w-300 {
    width: 300px !important;
}

.w-350 {
    width: 350px !important;
}

.w-400 {
    width: 400px !important;
}

.title-show-profile {
    text-align: left !important;
    font-family: "Roboto-medium" !important;
    font-size: 18px !important;
    line-height: 20px !important;
    letter-spacing: 0px !important;
    color: #1d268c !important;
    opacity: 1 !important;
    margin-bottom: 28px !important;
}

.title-show-video {
    text-align: left !important;
    font-family: "Roboto-medium" !important;
    font-size: 20px !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    color: #1d268c !important;
    opacity: 1 !important;
    margin-bottom: 28px !important;
}

.title-profile {
    text-align: left !important;
    font-family: "Roboto-regular" !important;
    font-size: 18px !important;
    line-height: 20px !important;
    letter-spacing: 0px !important;
    color: #1d268c !important;
    opacity: 1 !important;
    margin-bottom: 18px !important;
    margin-top: 1rem !important;
}

.title-video {
    text-align: left !important;
    font-family: "Roboto-medium" !important;
    font-size: 20px !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    color: #1d268c !important;
    opacity: 1 !important;
    margin-bottom: 18px !important;
    padding-left: 10px;
}

.title-look-profile {
    text-align: left !important;
    font-family: "Roboto-medium" !important;
    font-size: 22px !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    color: #1d268c !important;
    opacity: 1 !important;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
}

.description {
    text-align: left;
    font-family: "Roboto-light";
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #0a0a0a;
    opacity: 1;
    text-align: justify;
    margin-bottom: 50px !important;
}

.description-labor {
    text-align: left;
    font-family: "Roboto-regular";
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
    .labor {
        font-family: "Roboto-bold" !important;
        margin-right: 20px;
        margin-left: 10px;
    }
}

.lookFor-text {
    text-align: left;
    font-family: "Roboto-regular" !important;
    font-size: 18px !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    color: #1c105d !important;
}

.form-label.text-labor {
    font-family: "Roboto-regular" !important;
    font-size: 18px !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    color: #434343 !important;
}

.arrow-back {
    i {
        font-size: 16px;
        margin-right: 20px;
    }
    text-align: left;
    text-transform: uppercase;
    font-family: "Roboto-light";
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 5.62px;
    width: -webkit-fill-available !important;
}

.arrow-back.purple {
    color: #7d7f9b !important;
}

.icon-input {
    top: 68%;
    right: 5%;
    font-size: 14px;
}

.IT_info {
    .info-campany {
        padding: 10px;
        color: #0f1447 !important;
        opacity: 1 !important;
        font-family: "Roboto-meduim-italic";
        font-size: 18px !important;
        text-align: left;
        letter-spacing: 0px;
        line-height: 24px;
        background: rgb(127, 127, 127, 0.11);
    }
    .video-campany {
        padding: 10px;
        color: #0f1447 !important;
        opacity: 1 !important;
        font-family: "Roboto-meduim-italic";
        font-size: 18px !important;
        text-align: left;
        letter-spacing: 0px;
        line-height: 24px;
    }
}

.nothingToShow {
    position: relative !important;
    width: 100% !important;
    background-color: #f0f0f4 !important;
    padding: 40px 40px 40px 40px;
    border-radius: 17px;
    height: 300px;
    border: dashed rgb(29, 22, 111, 0.5);
    border-radius: 10px;
    background: transparent url("../../../assets/svg/video.svg") 50% 50% no-repeat padding-box;
    content-visibility: hidden !important;
    .span {
        position: absolute !important;
        color: black !important;
    }
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-7 {
    margin-top: 7px !important;
    @media screen and (max-width: 1600px) {
        margin-top: 9px !important;
    }
    @media screen and (max-width: 1366px) {
        margin-top: 11px !important;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 13px !important;
    }
    @media screen and (max-width: 900px) {
        margin-top: 15px !important;
    }
}
.img-profil{
    width: 100% !important;
    height: 100% !important;
    border-radius: 50%;
}
.img-section{
    height: 180px;
    margin-right: 10px;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}
.width-right-cell {
    @media screen and (max-width: 700px) {
        width: 133px !important; 
    }
}