.steps {
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  margin-top: 2rem;
  .step {
    margin: 0.5rem 3rem;
    //display: flex;
    color: #ffffff;
    text-align: center;

    div:first-of-type {
      border: 3px solid #3a3ece;
      padding: 10px;
      border-radius: 70%;
      margin-right: 0.5rem;
      width: 3rem;
      height: 3rem;
      text-align: center;
      line-height: 100%;
      padding-top: 10%;
      div:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #3a3ece;
        position: absolute;
        left: -50%;
        top: 18px;
        z-index: -1; /*put it behind the numbers*/
      }
    }

    :last-child {
      margin-top: 10%;
    }

    &.active {
      div:first-of-type {
        background-color: #3a3ece;
        border: 1px solid #3a3ece;
        color: #3a3ece;
      }
    }
  }
}
/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  //position: relative;
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  cursor: pointer;
  @media screen and (max-width:900px) {
    justify-content: center;
  }
  @media screen and (max-width:425px) {
    padding: 0 !important;
  }
}
#progressbar li {
  list-style-type: none;
  color: #ffffff;
  font-size: 20px;
  width: 14.33%;
  float: left;
  position: relative;
  letter-spacing: 1px;
  text-align: center;
  @media screen and (max-width:1600px) {
    font-size: 18px !important;
  }
  @media screen and (max-width:1440px) {
    font-size: 16px !important;
    width: 20.33%;
  }
  @media screen and (max-width:1360px) {
    font-size: 15px !important;
  }
  @media screen and (max-width:1024px) {
    font-size: 14px !important;
    line-height: 17px !important;
    width: 33.33%;
  }
  @media screen and (max-width:900px) {
    font-size: 14px !important;
    line-height: 17px !important;
  }
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 26px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: transparent;
  border-radius: 50px;
  border: 3px solid #3a3ece;
  margin: 0 auto 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 86%;
  height: 2px;
  background: #3a3ece;
  position: absolute;
  left: -43%;
  top: 18px;
  
  @media screen and (max-width:1600px) {
    width: 86%;
  }
  @media screen and (max-width:1440px) {
    width: 86%;
  }
  @media screen and (max-width:1360px) {
    width: 86%;
  }
  @media screen and (max-width:900px) {
    width: 85%;
  }
  @media screen and (max-width:425px) {
    width: 69%;
    left: -34%;
  }
}
#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #3a3ece;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepform {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 20px;
  opacity: 1;
  width: 45%;
  padding: 60px !important;
  margin: 3% auto 0;
  @media screen and (max-width:1440px) {
    width: 65%;
  }
  @media screen and (max-width:1024px) {
    width: 75%;
  }
  @media screen and (max-width:900px) {
    border-radius: 0;
    width: 100%;
  }
  .row {
    @media screen and (max-width:900px) {
      flex-direction: column;
    }
  }
}
.form-label.title-inscri {
  margin-top: 16px !important;
  font-family: "Roboto-regular" !important;
  font-size: 18px !important;
  text-align: left !important;
  letter-spacing: 0px !important;
  color: #1c105d !important;
  opacity: 1 !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 15px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 13px !important;
    margin: 16px 0 0 0 !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 11px !important;
  }
}

.title-Inscr {
  font-family: "Roboto-regular" !important;
  font-size: 18px !important;
  text-align: left !important;
  margin-top: 8px;
  margin-right: 10px;
  letter-spacing: 0px !important;
  color: #1c105d !important;
  opacity: 1 !important;
}
.button-next {
  background: #0f67d9 0% 0% no-repeat padding-box !important;
  border-radius: 30px !important;
  opacity: 1 !important;
  padding-left: 38px !important;
  padding-right: 38px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: "Roboto-bold";
  font-size: 18px !important;
  line-height: 22px !important;
  padding-top: 10px !important;
  padding-bottom: 9px !important;
  @media screen and (max-width:900px) {
    padding-left: 132px !important;
    padding-right: 150px !important;
    margin-top: 4% !important;
  }
  @media screen and (max-width:620px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 4% !important;
    width: 100%;
  }
}
.dropdown-skills {
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  opacity: 1;
}
.text-skills {
  font-family: "Roboto-medium";
  font-size: 18px !important;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}
.icon-supprimer {
  color: #434343;
  float: right;
  width: 40px;
  height: 38px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding-top: 10px;
  padding-left: 10px;
}
.required:after {
  content: " *";
  color: red;
}
.height-label {
  @media screen and (max-width: 1280px) {
    margin-bottom: 11% !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 0% !important;
  }
}
.padding-top-title {
  @media screen and (max-width: 1280px) {
    margin-bottom: 20% !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 0% !important;
  }
}
