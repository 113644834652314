.interviews-paragraphe {
  border: 1px solid #434cb4;
  border-radius: 14px;
  opacity: 1;
  padding: 25px 24px 15px 24px !important;
}

.paragraphe-style {
  font-family: "Roboto-regular" !important;
  font-size: 18px !important;
  line-height: 29px !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.86;
}
.banner-interview {
  margin-left: 10% !important;
  @media screen and (max-width: 1800px) {
    margin-left: 5%;
  }
  @media screen and (max-width: 1340px) {
    margin-left: 2%;
  }
}

#banner{
  width: 100%;
  height: 70%;
  @media screen and (max-width: 1600px) {
    height: 50%;
  }
  @media screen and (max-width: 1280px) {
    height: 35%;
  }
}

.width-screen {
  width: auto;
  height: auto;
  margin-top: 3rem;
  @media screen and (max-width: 1600px) {
    margin-top: 37px !important;
  }
  @media screen and (max-width: 1366px) {
    height: 67%;
    margin-top: 16% !important;
  }
  @media screen and (max-width: 1280px) {
    height: 57%;
    margin-top: 28% !important;
  }
}

.agenda-picker {
  background: #171154 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  opacity: 1;
}
.text-agenda {
  text-align: left;
  font-family: "Roboto-regular";
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 10px 10px !important;
}
.text-interviews-Up {
  font-family: "Roboto-regular";
  font-size: 14px;
  text-align: left;

  letter-spacing: 0px;
  color: #0f1447;
  opacity: 1;
}

.upcoming-interviews-rectangle {
  background: #d52bff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 20px;
  height: 20px;
}

.upcoming-interviews-rectangle-2 {
  width: 20px;
  height: 20px;
  border: 1px solid #d52bff;
  border-radius: 6px;
  opacity: 1;
}
.marge-right-10 {
  margin-right: 47px;
}

.margin-bottom-10 {
  margin-bottom: 30px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.banner-image {
  float: right;
  height: 266px;
}

.mr-70 {
  margin-right: 70px !important;

  @media screen and (max-width: 1280px) {
    margin-right: 0px !important;
  }
  @media screen and (max-width: 1366px) {
    margin-right: 0px !important;
  }
}
