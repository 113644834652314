/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "primary": #0f67d9,
  "secondary": #ffc937,
  "tertiary": #2df8c0,
  "light-blue": #4bd4e2,
  "danger": #fd4343,
  "default": #fff,
  "sidebar": #23378c,
  "purple": #1c1845,
  "gray": #8689a2,
  "green": #27ae89,
  "lightgray": #d7d8e0,
  "navy": #1c105d,
  "dark": #0f1447,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap.scss";

body {
  font-family: "Roboto-regular";
  font-size: 18px;
  color: white;
  overflow: auto;
  padding-right: unset !important;
}

.header-Content {
  background: url("./assets/bg/bgHeader.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 100px 100px 50px 100px;
  width: 100%;
}
@media screen and (max-width: 750px) {
  .header-Content {
    padding: 100px 20px 50px 20px !important;
  }
}
.main {
  padding: 50px 100px 100px 100px;
  background-color: #fbfbfb !important;
  @media screen and (max-width: 900px) {
    padding: 50px 30px 100px 30px !important;
  }
}
.main-without-header {
  padding: 120px 100px 100px 100px;
  background-color: #fbfbfb !important;
  @media screen and (max-width: 700px) {
    padding: 115px 5% 10% !important;
  }
  @media screen and (max-width: 375px) {
    padding: 115px 8% 10% !important;
  }
}

body {
  background-color: #fbfbfb !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.text-align-last-right {
  text-align-last: right !important;
}

.text-align-center {
  text-align: center !important;
}

.justify-space-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -moz-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: end !important;
  -moz-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -moz-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
  -moz-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.place-content-center {
  place-content: center !important;
}

.text-align-end {
  text-align: end !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-italic {
  font-style: italic !important;
}

.text-underline {
  text-decoration: underline !important;
}

.align-items-center {
  align-items: center !important;
}

.pointer {
  cursor: pointer !important;
}

// start Agenda style

.DayPicker-NavButton--next {
  background-image: url("./assets/svg/nextAgenda.svg");
}

.DayPicker-NavButton--prev {
  background-image: url("./assets/svg/prevAgenda.svg");
}

.DayPicker-Day--today {
  color: #ffffff !important;
  font-weight: normal !important;
}

.DayPicker {
  font-size: 1rem !important;
  @media screen and (max-width: 1600px) {
    font-size: 1rem !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 0.9rem !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 0.8rem !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem !important;
  }
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: transparent;
}

/* */
.text-calendar {
  font-family: "Roboto-regular";
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #0f1447;
  opacity: 1;
  @media screen and (max-width: 1600px) {
    font-size: 13px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 11px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 10px !important;
  }
}

.text-calendar.pink {
  .form-check-input[type="checkbox"] {
    cursor: pointer;
    border: 1px solid #d52bff !important;
  }
  .form-check-input:checked {
    background-color: #d52bff;
    border-color: #d52bff;
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: none !important;
  }
}

.text-calendar.blue {
  .form-check-input[type="checkbox"] {
    cursor: pointer;
    border: 1px solid #0f67d9 !important;
  }
  .form-check-input:checked {
    background-color: #0f67d9;
    border-color: #0f67d9;
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: none !important;
  }
}

.text-calendar.purple {
  .form-check-input[type="checkbox"] {
    cursor: pointer;
    border: 1px solid #4653dd !important;
  }
  .form-check-input:checked {
    background-color: #4653dd;
    border-color: #4653dd;
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: none !important;
  }
}

// start label colored style
.text-length {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  font-family: "Roboto-bold" !important;
  font-size: 18px !important;
  text-align: center;
  height: 28px;
  width: 34px;
  padding: 2px !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 18px !important;
  }
}
.text-length.pink {
  background-color: rgb(213, 43, 255, 0.2);
  color: #d52bff;
}
.text-length.blue {
  background-color: rgb(15, 103, 217, 0.2);
  color: #1f1b7a;
}
.text-length.green {
  background: rgba(44, 158, 127, 0.3);
  color: #2c9e7f;
}
.text-length.red {
  background: rgba(206, 35, 35, 0.3);
  color: #ce2323;
}
.text-length.gray {
  background: rgba(130, 131, 150, 0.3);
  color: #828396;
}
.text-tag {
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  font-family: "Roboto-medium" !important;
  font-size: 14px !important;
  padding: 4px 17px !important;
  -moz-text-align-last: center !important;
  text-align-last: center !important;
  min-width: 120px;
  @media screen and (max-width: 1600px) {
    font-size: 13px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 11px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 12px !important;
  }
}

.text-tag-dashboard {
  font-family: "Roboto-regular" !important;
  font-size: 14px !important;
  padding: 2px 5px !important;
  -moz-text-align-last: center !important;
  text-align-last: center !important;
  @media screen and (max-width: 1600px) {
    font-size: 13px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 11px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 10px !important;
  }
}
.text-tag-dashboard.blue {
  background-color: rgba(15, 101, 215, 0.2);
  color: #0f67d9;
}
.text-tag.blue {
  background-color: rgba(15, 101, 215, 0.2);
  color: #0f67d9;
}

.text-tag.red {
  background: rgba(226, 46, 46, 0.2);
  color: #e22e2e;
}

.text-tag.green {
  background-color: rgba(44, 158, 127, 0.2);
  color: #2c9e7f;
}

.text-tag.black {
  background-color: rgba(0, 0, 0, 0.2);
  color: #000000;
}

.text-tag.pink {
  background-color: rgba(212, 41, 255, 0.2);
  color: #d52bff;
}

.text-tag.violet {
  background-color: rgba(153, 117, 225, 0.2);
  color: #9975e1;
}

.text-tag.yellow {
  background-color: rgba(235, 176, 73, 0.2);
  color: #ebb049;
}

// End label colored style

.card {
  position: unset;
}

.text-detail {
  font-family: "Roboto-regular" !important;
  font-size: 16px !important;
  line-height: 19px;
  text-align: left;
  color: #828396;
  opacity: 1;
  letter-spacing: 0px;
  @media screen and (max-width: 1600px) {
    font-size: 14px !important;
    line-height: 17px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
    line-height: 15px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 10px !important;
    line-height: 13px;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
    line-height: 19px;
  }
}

.title-page {
  font-family: "Roboto-medium";
  font-size: 28px;
  text-align: left;
  letter-spacing: 0px;
  color: #1c105d;
  opacity: 1;
  line-height: 28px;
  @media screen and (max-width: 1600px) {
    font-size: 23px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 21px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 19px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 17px !important;
  }
}

.sub-title-page {
  font-family: "Roboto-regular";
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: #1d268c;
  opacity: 1;
  @media screen and (max-width: 1600px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 15px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 18px !important;
  }
}

.text-header {
  font-family: "Roboto-medium" !important;
  font-size: 18px !important;
  letter-spacing: 0px !important;
  color: #0f1447 !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 20px !important;
  }
}

/* Video Style Start */
iframe {
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;
}
/* Video Style End */
/* */
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.scroll-downs {
  position: relative;
  right: 0;
  left: 0;
  margin: auto;
  width: 34px;
  height: 55px;
  @media screen and (max-width: 700px) {
    padding-top: 15% !important;
  }
  @media screen and (max-width: 425px) {
    padding-top: 0% !important;
  }
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
.w-height-image {
  width: 250px !important;
  height: 250px !important;
  @media screen and (max-width: 1600px) {
    width: 188px !important;
    height: 188px !important;
  }
  @media screen and (max-width: 1366px) {
    width: 170px !important;
    height: 170px !important;
  }
  @media screen and (max-width: 1280px) {
    width: 176px !important;
    height: 176px !important;
  }
}
.height-image {
  width: 150px !important;
  height: 150px !important;
}
.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: #959698;
  border-radius: 4px;
}
