.margin-bottom-follow {
    @media screen and (max-width: 1600px) {
        margin-bottom: 1.5rem !important;
    }
}
tbody tr > td:nth-child(6) p{
    min-width: 80px;
}
tbody tr > td:nth-child(3) p{
    min-width: 120px;
}

#cadidat-badge{
    margin-right: 10px;
    @media screen and (max-width: 1600px) {
        margin-right: 20px;
    }
    @media screen and (max-width: 1000px) {
        margin-right: 10px;
    }
}

.tbody-td-folow {
    @media screen and (max-width: 1600px) {
        min-width: 130px !important;
    }
    @media screen and (max-width: 1280px) {
        min-width: 130px !important;
    }
}