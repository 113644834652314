.sidebar {
  z-index: 1;
  width: 120px;
  height: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-flex-wrap: unset !important;
  -ms-flex-wrap: unset !important;
  flex-wrap: unset !important;
  .nav-link {
    padding: 18%;
    color: #9da5f9 !important;
    background-color: unset !important;
    width: 100%;
    span {
      font-size: 14px;
    }
    i {
      font-size: 25px;
    }
    @media screen and (max-width: 1024px) {
      padding: 9%;
    }
    @media screen and (max-width: 425px) {
      padding: 13%;
    }
    @media screen and (max-width: 375px) {
    padding: 15%;
  }
  }
  @media screen and (max-width: 1024px) {
    width: 120px;
  }
  @media screen and (max-width: 425px) {
    width: 40%;
  }
  @media screen and (max-width: 375px) {
    width: 32%;
  }

  .navlink-sidebar{
    margin:15px;
  }

  #ham-icon{
    background-color:#212768;
    padding:15px;
    border-radius:20px;
  }

  .nav-link.is-active {
    background-color: #212768 !important;
    color: #ffff !important;
  }
  .nav-link:hover {
    color: #ffff !important;
    background: #212768 !important;
  }
  .nav-link.menu-Nav {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: -o-transform 0.3s;
    -moz-transition: transform 0.3s, -moz-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s, -moz-transform 0.3s,
      -o-transform 0.3s;
    i {
      font-size: 25px !important;
    }
  }
  .nav-link.menu-Nav:hover {
    background: none !important;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@media screen and (max-width: 425px) {
   .nav-link.menu-Nav {
     margin: 0 auto !important;
   }
}
.text-underline-none {
  text-decoration: none !important;
}
.shadow-unset {
  -webkit-box-shadow: unset !important;
  -moz-box-shadow: unset !important;
  box-shadow: unset !important;
}
.sidebar::-webkit-scrollbar {
  width: 0px;
}
.size-35 {
  font-size: 35px !important;
}
.float-mobile {
  @media screen and (max-width: 1024px) {
    float: left !important;
  }

}
.display-sidebar-mobile {
  display: none;
  @media screen and (max-width: 1024px) {
    display: inline;
    width: 72%;
    margin-left: 7%;
  }
  @media screen and (max-width: 425px) {
   display: none;
  }
}
.toggle-sidebar{
  @media screen and (max-width: 425px) {
    display: block;
    margin: 2px auto;
  }
}

#burger{
  display: flex;
  flex-direction: column;
  align-items: center;
}