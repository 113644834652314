.title-document-recruiter {
    font-size: 18px !important;
    font-family: "Roboto-medium";
    letter-spacing: 0px;
    color: #1D268C !important;
    opacity: 1;
}
.date-document {
    font-size: 18px !important;
    letter-spacing: 0px;
    color: #171154 !important;
    opacity: 1;
}