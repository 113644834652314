.r-inscription1 {
  background: linear-gradient(180deg, #19002a, #272eaf, #2f84ba);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 0;
  overflow: hidden;
  .btn-recrute {
    border-color: #2df8c0 !important;
    background-color: #2df8c0 !important;
    color: #211f85 !important;
    box-shadow: none !important;
    border-radius: unset !important;
    padding: 15px 18px 13px 18px !important;
    font-size: 23px !important;
    line-height: 26px !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 1600px) {
      font-size: 20px !important;
      padding: 13px 30px 11px 30px !important;
    }
    @media screen and (max-width: 1440px) {
      font-size: 18px !important;
      padding: 11px 45px 11px 45px !important;
    }
    @media screen and (max-width: 1366px) {
      font-size: 16px !important;
      padding: 10px 40px 10px 40px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 15px !important;
      padding: 9px 35px 9px 35px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px !important;
      padding: 9px 40px 9px 40px !important;
    }
    @media screen and (max-width: 980px) {
      font-size: 14px !important;
      padding: 8px 35px 8px 35px !important;
    }
  }
  .r-overlay {
    background-image: url("../../assets/img/inscription/r-inscrit1.png");
    position: absolute;
    width: 100%;
    height: 123%;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0%;
    z-index: -1;
    @media screen and (max-width:1600px) {
      height: 129%;
    }
  }
  .r-etoile {
    background-image: url("../../assets/img/inscription/etoile-props.png");
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    top: 55%;
  }
  .margin-title {
    @media screen and (max-width:900px) {
      margin-top: 33% !important;
    }
  }
}
.r-inscription2 {
  background: linear-gradient(180deg, #2f84ba, #2dbebd, #1a2a8b, #1a1554);
  height: 100%;
  min-height: 100vh;
  .d-flex {
    @media screen and (max-width:900px) {
      flex-direction: column;
    }
  }
  .margin-flex {
    margin-top: 10%;
  }
  .r-screen {
    width: 100%;
    height: 70%;
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    top:100%;
    position: absolute;
    z-index: 0;
  }
  .description {
    margin-top: 18%;
    opacity: 0.85;
  }
  .r-overlay {
    width: 95%;
    height: 95%;
    margin-left: 14%;
    @media screen and (max-width:1366px) {
      width: 75%;
      height: 90%;
      margin-left: 8%;
    }
    @media screen and (max-width:900px) {
      width: 50%;
      height: 75%;
      margin-left: 24%;
    }
  }
  .description1 {
    text-align: left;
    font-size: 60px !important;
    line-height: 80px;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    @media screen and (max-width: 1280px) {
      font-size: 30px !important;
      line-height: 35px;
    }
  }
  .r-etoile {
    width: 10px !important;
    height: 10px !important;
  }
  .description3 {
    font-size: 40px !important;
    line-height: 58px;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }
  .btn-recrute {
    margin-top: 4rem;
    border-color: #ffc937 !important;
    background-color: #ffc937 !important;
    color: #211f85 !important;
    box-shadow: none !important;
    border-radius: unset !important;
    padding: 15px 18px 13px 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 1600px) {
      font-size: 20px !important;
      padding: 13px 30px 11px 30px !important;
    }
    @media screen and (max-width: 1440px) {
      font-size: 18px !important;
      padding: 11px 45px 11px 45px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px !important;
      padding: 9px 40px 9px 40px !important;
    }
    @media screen and (max-width: 980px) {
      font-size: 14px !important;
      padding: 8px 35px 8px 35px !important;
    }
  }
  .div-one {
    @media screen and (max-width:900px) {
      float: left !important;
      padding-right: 11px !important;
    }
  }
  .button-position{
    text-align: center !important;
  }
}
.r-inscription3 {
  background: linear-gradient(180deg, #1a1554, #2f2074);
  height: 100%;
  min-height: 110vh;
  position: relative;
  z-index: 0;
  .padding-title {
    padding-top: 10%;
  }
  .padding-description {
    padding-top: 19%;
    float: right;
    padding-right: 8%;
    @media screen and (max-width:1366px) {
      margin-bottom: -2rem;
    }
    @media screen and (max-width:900px) {
      float: none;
      padding-right: 0%;
      text-align: center !important;
    }
  }
  .padding-description1 {
    float: right;
    padding-right: 5%;
    @media screen and (max-width:900px) {
      float: none;
      padding-right: 0%;
      text-align: center !important;
    }
  }
  .inscrit-fusil {
    top: 44%;
    left: -7%;
    position: absolute;
    @media screen and (max-width:1366px) {
      width: 50%;
      height: 55%;
      top: 36%;
    }
    @media screen and (max-width:900px) {
      display: none;
    }
  }
  .elipse {
    background-image: url("../../assets/img/inscription/Ellipse.png");
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    width: 100%;
    height: 68%;
    top: 7%;
    position: absolute;
    background-size: contain;
  }
  .elipse1 {
    background-image: url("../../assets/img/inscription/etoile-props.png");
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    width: 87%;
    height: 51%;
    /* top: 29%; */
    position: absolute;
    background-size: cover;
  }
}
.r-inscription4 {
  background: linear-gradient(180deg, #2f2074, #6c44ce, #6c44ce, #2fb6c0);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: -4;
  @media screen and (max-width:900px) {
    z-index: 0;
    min-height: 50vh;
  }
  .diamant-inscrit {
    background-repeat: no-repeat;
    position: absolute;
    top: 0%;
    left: 4%;
    width: 10%;
    mix-blend-mode: screen;
    @media screen and (max-width:900px) {
      display: none;
    }
  }
  .d-flex-1 {
    background-image: url("../../assets/img/inscription/inscrit-pg.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 6%;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-evenly !important;
    @media screen and (max-width:900px) {
      justify-content: start !important;
      padding-top: 0%;
      padding-left: 0%;
      padding-right: 0%;
      width: 100% !important;
      position: absolute;
      height: 100% !important;
    }
    @media screen and (max-width:900px){
    background-size: contain;
  }
  }
  .d-flex-2 {
    justify-content: space-around !important;
    padding-left: 5%;
    padding-right: 5%;
  }
  #delete-div{
    @media screen and (max-width: 1600px) {
      display: none;
    }
    @media screen and (max-width: 1280px) {
      display: none;
    }
  }
  .position-image{
    @media screen and (max-width:900px) {
      width: 50%;
      position: absolute;
      top: -42%;
      left: 21%;
    }
  }
  .delete-image{
    @media screen and (max-width: 900px) {
      display: none !important;
      visibility: hidden;
    }
  }
}
.r-inscription5 {
  background: linear-gradient(180deg, #2fb6c0, #329de4);
  height: 100%;
  min-height: 100vh;
  position: relative;
  .cosmonaute {
    position: absolute;
    left: 8%;
    top: 19%;
    @media screen and (max-width:900px) {
      width: 42%;
      left: 27%;
      top: 31%;
    }
  }
  .cosmonaute1 {
    @media screen and (max-width:900px) {
      width: 64%;
      position: absolute;
      left: 17%;
      top: 21%;
    }
  }
  .fontSize-90 {
    padding-top: 20%;
  }
  .float-left-div{
    @media screen and (max-width:900px) {
      float: left;
      padding-left: 4%;
    }
  }
  .padding-text{ 
    @media screen and (max-width:900px) {
      padding-left: 4% !important;
    }
  }
  .margin-title {
    @media screen and (max-width:900px) {
      margin-bottom: 67%;
    }
  }
}
.r-inscription6 {
  background: linear-gradient(180deg, #329de4, #261c72);
  height: 100%;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width:900px) {
    min-height: 75vh !important;
  }
  .overlay-inscrit {
    top: 2%;
    position: absolute;
    z-index: 999999999;
    height: 75%;
    width: 100%;
    background-repeat: no-repeat !important;
  }
  .r-screen {
    background-repeat: no-repeat !important;
    mix-blend-mode: screen;
    top: 10%;
    position: absolute;
  }
  .padding-l {
    padding-left: 11% !important;
    padding-top: 15%;
    @media screen and (max-width:900px) {
      padding-left: 0% !important;
      padding-top: 0% !important;
    }
  }
  .display-image {
    @media screen and (max-width:900px) {
      width: 14%;
    }
  }
  .float-div{
    @media screen and (max-width:900px) {
      float: left;
      line-height: 20px !important;
      margin-left: 4% !important;
      padding-top: 10%;
    }
  }
  .float-div1{
    @media screen and (max-width:900px) {
      float: left;
      margin-left: 4%;
    }
  }
}
.r-inscription8 {
  background: linear-gradient(180deg, #261c72, #9d32e4);
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 0;
  .btn-recrute {
    margin-top: 4rem;
    border-color: #ffc937 !important;
    background-color: #ffc937 !important;
    color: #211f85 !important;
    box-shadow: none !important;
    border-radius: unset !important;
    padding: 15px 18px 13px 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    @media screen and (max-width: 1600px) {
      font-size: 17px !important;
      padding: 17px 30px 11px 30px !important;
    }
    @media screen and (max-width: 1440px) {
      font-size: 15px !important;
      padding: 12px 45px 11px 45px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px !important;
      padding: 9px 40px 9px 40px !important;
    }
    @media screen and (max-width: 980px) {
      font-size: 12px !important;
      padding: 8px 35px 8px 35px !important;
    }
  }
  .text-margin {
    margin-bottom: -2rem !important;
  }
  .text-spacing {
    margin-bottom: -1rem !important;
  }
  .spacing-flex {
    padding-left: 7% !important;
    padding-right: 7% !important;
    flex-direction: column;
  }
  .padding-t {
    padding-top: 5%;
  }
  .elipse {
    z-index: -3;
    position: absolute;
    width: 100%;
    height: 77%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .image-position {
      width: 20%;
      position: absolute;
      top: -10%; 
    @media screen and (max-width:900px) {
      width: 31%;
      position: absolute;
      top: -5%; 
    }
  }
  .div-height{
    @media  screen and (max-width:900px) {
      padding-top: 15%;
    line-height: 47px !important;
    }
  }
  .d-flex-1 {
    display: flex;
    @media screen and (max-width:900px) {
      display: table-cell;
    }
  }
}
.r-inscription9 {
  background: linear-gradient(180deg, #9d32e4, #6b28b4);
  height: 100%;
  min-height: 100vh;
  position: relative;
  .text-space {
    margin-bottom: -2rem;
  }
  .padding-l {
    padding-left: 8%;
    padding-top: 8%;
  }
  .planet {
    position: absolute;
    width: 12%;
    height: 65%;
    left: 89%;
    top: 32%;
    @media screen and (max-width:900px) {
      height: 10%;
      top: 50%;
    }
  }
  .masques {
    background: url("../../assets/img/inscription/masques.png");
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.r-inscription10 {
  background: linear-gradient(180deg, #6b28b4, #261c72);
  height: 100%;
  min-height: 100vh;
  position: relative;
  .elipse1 {
    position: absolute;
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    @media screen and (max-width:900px) {
      top: -2%;
      left: -37%;
    }
  }
  .lamp {
    background-size: cover;
    position: absolute;
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    top: -12%;
    @media screen and (max-width:900px) {
      width: 106%;
      top: 18%;
    }
  }
  .padding-l {
    padding-left: 8%;
    @media screen and (max-width:900px) {
      margin-bottom: 110%;
      padding-left: 4%;
    }
  }
  .text-space {
    margin-bottom: -2rem !important;
  }
  .flag {
    height: 85%;
    width: 90%;
    @media screen and (max-width:900px) {
      position: absolute;
      height: 42%;
      width: 78%;
      top: 25%;
      background-repeat: no-repeat;
    }
  }
  .text-space2 {
    margin-bottom: -3rem !important;
  }
  .d-flex-1{
    display: flex;
    @media screen and (max-width:900px) {
      display: inline-block;
      margin-left: 4%;
    }
  }
  .float-div{
    @media screen and (max-width:900px) {
      float: left !important ;
    }
  }
}
.r-inscription11 {
  background: linear-gradient(180deg, #261c72, #161b49);
  height: 100%;
  min-height: 100vh;
  position: relative;
  .text-space {
    margin-bottom: -2rem !important;
  }
  .text-space2 {
    margin-bottom: -5rem !important;
  }
  .padding-t {
    padding-top: 1rem;
    padding-bottom: 7rem;
  }
  .planet-1 {
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    width: 7%;
  }
}
.text-spacing {
  margin-bottom: -1px;
}
.opacity-text {
  opacity: 0.85;
}
.text-space2 {
  margin-bottom: -5rem;
}

.notice a{
  color: #fff;
  text-decoration: none;
}
.notice a:hover{
  cursor: pointer;
  color: #fff;
  opacity: 0.75;
}