/* Icons fonts */
@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?e3qux0");
  src: url("./assets/fonts/icomoon.eot?e3qux0#iefix") format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?e3qux0") format("truetype"),
    url("./assets/fonts/icomoon.woff?e3qux0") format("woff"),
    url("./assets/fonts/icomoon.svg?e3qux0#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak-as: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-missions:before {
  content: "\e900";
}

.icon-interviews:before {
  content: "\e901";
}

.icon-documents:before {
  content: "\e902";
}

.icon-Dashborad:before {
  content: "\e903";
}

.icon-ask-admin:before {
  content: "\e904";
}

.icon-quiz:before {
  content: "\e905";
}

.icon-Profil:before {
  content: "\e906";
}

.icon-help:before {
  content: "\e907";
}

.icon-Constellation:before {
  content: "\e908";
}

.icon-more:before {
  content: "\e909";
}

.icon-communication-greenCard:before {
  content: "\e90a";
}

.icon-url:before {
  content: "\e90b";
}

.icon-Upload:before {
  content: "\e90c";
}

.icon-checked-radio:before {
  content: "\e90d";
}

.icon-profile:before {
  content: "\e90e";
}

.icon-pin:before {
  content: "\e90f";
  color: #828396;
}

.icon-notification:before {
  content: "\e910";
  color: #ffffff;
}

.icon-notification-line:before {
  content: "\e911";
}

.icon-email:before {
  content: "\e912";
}

.icon-down-arrow:before {
  content: "\e913";
}

.icon-close:before {
  content: "\e914";
}

.icon-call:before {
  content: "\e915";
}

.icon-boost:before {
  content: "\e916";
}

.icon-back-arrow:before {
  content: "\e917";
}

.icon-arrow-left:before {
  content: "\e918";
}

.icon-agenda:before {
  content: "\e919";
  color: #828396;
}

.icon-edit:before {
  content: "\e91a";
  color: #b1b1b1;
}

.icon-euro:before {
  content: "\e91b";
  color: #0f1447;
}

.icon-delete:before {
  content: "\e91c";
  color: #84858e;
}

.icon-Record:before {
  content: "\e91d";
  color: #fff;
}

.icon-video:before {
  content: "\e91e";
  color: #dddde9;
}

.icon-accepted .path1:before {
  content: "\e91f";
  color: rgb(15, 20, 71);
  opacity: 0.22;
}

.icon-accepted .path2:before {
  content: "\e920";
  margin-left: -1.1982421875em;
  color: rgb(15, 20, 71);
}

.icon-accepted .path3:before {
  content: "\e921";
  margin-left: -1.1982421875em;
  color: rgb(15, 20, 71);
}

.icon-accepted .path4:before {
  content: "\e922";
  margin-left: -1.1982421875em;
  color: rgb(15, 20, 71);
}

.icon-accepted .path5:before {
  content: "\e923";
  margin-left: -1.1982421875em;
  color: rgb(15, 20, 71);
}

.icon-arrow-down-select-list:before {
  content: "\e924";
  color: #ffffff;
}

.icon-end-date:before {
  content: "\e925";
  color: #828396;
}

.icon-time:before {
  content: "\e926";
  color: #1c105d;
}

.icon-close-popup:before {
  content: "\e927";
  color: #0f1447;
}

.icon-english .path1:before {
  content: "\e928";
  color: rgb(1, 61, 131);
}

.icon-english .path2:before {
  content: "\e929";
  margin-left: -1.2861328125em;
  color: rgb(255, 255, 255);
}

.icon-english .path3:before {
  content: "\e92a";
  margin-left: -1.2861328125em;
  color: rgb(227, 2, 42);
}

.icon-english .path4:before {
  content: "\e92b";
  margin-left: -1.2861328125em;
  color: rgb(255, 255, 255);
}

.icon-english .path5:before {
  content: "\e92c";
  margin-left: -1.2861328125em;
  color: rgb(206, 17, 36);
}

.icon-english .path6:before {
  content: "\e92d";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-english .path7:before {
  content: "\e92e";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-english .path8:before {
  content: "\e92f";
  margin-left: -1.2861328125em;
  color: rgb(206, 17, 36);
}

.icon-english .path9:before {
  content: "\e930";
  margin-left: -1.2861328125em;
  color: rgb(206, 17, 36);
}

.icon-english .path10:before {
  content: "\e931";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-english .path11:before {
  content: "\e932";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-english .path12:before {
  content: "\e933";
  margin-left: -1.2861328125em;
  color: rgb(206, 17, 36);
}

.icon-english .path13:before {
  content: "\e934";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-english .path14:before {
  content: "\e935";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-english .path15:before {
  content: "\e936";
  margin-left: -1.2861328125em;
  color: rgb(206, 17, 36);
}

.icon-english .path16:before {
  content: "\e937";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-english .path17:before {
  content: "\e938";
  margin-left: -1.2861328125em;
  color: rgb(0, 36, 125);
}

.icon-france .path1:before {
  content: "\e939";
  color: rgb(1, 61, 131);
}

.icon-france .path2:before {
  content: "\e93a";
  margin-left: -1.2861328125em;
  color: rgb(255, 255, 255);
}

.icon-france .path3:before {
  content: "\e93b";
  margin-left: -1.2861328125em;
  color: rgb(227, 2, 42);
}

.icon-hamburger:before {
  content: "\e93c";
}

.icon-hamburger-btn:before {
  content: "\e93c";
  color: #9da5f9;
}

.icon-camera:before {
  content: "\e93d";
  color: #8c89ab;
}

.icon-arrow-left2:before {
  content: "\e93e";
  color: #2c9e7f;
}

.icon-see-more:before {
  content: "\e93f";
  color: #8183b8;
}
