.nav-tabs {
    font-family: "Roboto-bold" !important;
    font-size: 18px !important;
    letter-spacing: 0px !important;
    color: #1d268c !important;
}

.DayPicker-Month {
    width: 100% !important;
}
.opacity-0 {
    opacity: 0;
}

#dropdown-basic, #dropdown-basic:hover{
    background-color: unset;
    border: none;
}
#dropdown-basic::after{
    display: none;
}
#dropdown-basic:focus{
    box-shadow: none;
}
.tooltip-transform{
   transform: translate(1430px, 710px) !important;
   background-color: white;
   @media screen and (max-width: 1600px) {
    transform: translate3d(1302px, 610px, 0px)!important;
  }
   @media screen and (max-width: 1366px) {
    transform: translate3d(1033px, 623.5px, 0px)!important;
  }
   @media screen and (max-width: 1280px) {
    transform: translate3d(1000px, 680px, 0px) !important;
  }
  
}
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: black !important;
    text-align: center;
    border-radius: 0.25rem;
    background-color: white !important;
}
.filter-interview-page {
    @media screen and (max-width: 1600px) {
        margin-top: 1.5rem !important;
    }
}
