.iscriptionPage {
  overflow-x: hidden;
}
.bg-inspirnaute-sec1 {
  background: linear-gradient(180deg, #180236, #252caa, #2e82b9);
  position: relative;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.bg-inspirnaute-sec2 {
  background: linear-gradient(180deg, #2e82b9, #9637e1);
  position: relative;
  height: 100%;
  z-index: -1;
  padding-top: 4%;
}
.bg-inspirnaute-section2 {
  background: linear-gradient(180deg, #9637e1, #9637e1);
  position: relative;
  height: 100%;
  z-index: -4;
  padding-top: 12%;
  padding-bottom: 10%;
  @media screen and (max-width: 900px) {
    padding-top: 0% !important;
  }
}
.bg-inspirnaute-sec3 {
  background: linear-gradient(180deg, #9637e1, #8346da, #2f84ba);
  position: relative;
  height: 100vh;
  z-index: 0;
}
.bg-inspirnaute-sec4 {
  background: linear-gradient(180deg, #2f84ba, #329de4);
  position: relative;
  height: 105vh;
  z-index: 0;
}
.bg-inspirnaute-sec5 {
  background: linear-gradient(180deg, #329de4, #2fbaba, #7f76c8);
  position: relative;
  height: 100vh;
  z-index: 0;
}
.bg-inspirnaute-sec6 {
  background: linear-gradient(180deg, #7f76c8, #9b60ce);
  position: relative;
  height: 100vh;
  z-index: 0;
}
.bg-inspirnaute-sec7 {
  background: linear-gradient(180deg, #9b60ce, #1e1569);
  position: relative;
  height: 100vh;
  z-index: 1;
}
.inspirnaute-block {
  @media screen and (max-width: 900px) {
    margin-left: 4% !important;
  }
}
.bg-inspirnaute-sec8 {
  background: linear-gradient(180deg, #1e1569, #151b47);
  height: 100%;
  z-index: 999999;
}
.bg-inspirnaute-sec9 {
  background: linear-gradient(180deg, #151b47, #151b47);
  height: 100%;
}
.slogan-talent {
  font-size: 40px !important;
  letter-spacing: 0px;
  color: #ffc937;
  text-transform: uppercase;
  opacity: 1;
  font-family: "Roboto-medium";
  @media screen and (max-width: 1280px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 24px !important;
  }
}
.description-section {
  font-size: 30px !important ;
  line-height: 35px;
  font-family: "Roboto-medium";
  color: #ffffff;
  opacity: 0.85;
  @media screen and (max-width: 1280px) {
    font-size: 26px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
    line-height: 20px !important;
    margin-left: 0% !important;
  }
}
.description-cosmonaute {
  font-size: 30px !important ;
  line-height: 37px;
  font-family: "Roboto-medium";
  color: #ffffff;
  opacity: 0.85;
  @media screen and (max-width: 1280px) {
    font-size: 23px !important;
    line-height: 32px;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
    line-height: 19px !important;
    margin-left: 4% !important;
  }
}

.description2 {
  font-family: "Roboto-medium";
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
  opacity: 0.85;
  margin-left: 60%;
  @media screen and (max-width: 1280px) {
    margin-left: 50% !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
    line-height: 22px;
  }
}
.description5 {
  margin-left: 40% !important;
  font-size: 30px !important ;
  line-height: 35px;
  font-family: "Roboto-medium";
  color: #ffffff;
  opacity: 0.85;
  @media screen and (max-width: 1280px) {
    font-size: 27px !important;
    line-height: 30px;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
    line-height: 19px !important;
    margin-left: 4% !important;
  }
}
.overlay-sections1 {
  background-image: url("../../assets/img/inscription/overlay_section1.png");
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 38%;
  overflow-x: hidden;
}

.overlay-section2 {
  background-image: url("../../assets/img/inscription/bg-overlay-section2.png");
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 150%;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0%;
  left: 6%;
  @media screen and (max-width: 1280px) {
    height: 130%;
    left: -8%;
    top: 2%;
  }
  @media screen and (max-width: 900px) {
    height: 130%;
    left: -8%;
    top: 45%;
  }
}
.mt-15 {
  margin-top: 7% !important ;
}
.overlay-section3 {
  // background-image: url("../../assets/img/inscription/bg-overlay-section3.png");
  position: absolute;
  z-index: -1;
  width: 90%;
  height: 100%;
  // background-size: cover;
  background-repeat: no-repeat !important;
  top: -16%;
  left: 11%;
  @media screen and (max-width: 1280px) {
    height: 98%;
    left: 9%;
    width: 100%;
  }
  @media screen and (max-width:900px) {
    height: 45%;
    left: -30%;
    width: 116%;
    top: 36%;
  }
}
.overlay-section4 {
  background-image: url("../../assets/img/inscription/illustration-section4.png");
  position: absolute;
  z-index: -1;
  width: 35%;
  height: 65%;
  background-size: cover;
  background-repeat: no-repeat;
  left: 4%;
  @media screen and (max-width: 900px) {
    background-size: contain;
    top: 38% !important;
    width: 50% !important;
    left: 18% !important;
  }
}
.overlay-section-bg4 {
  background-image: url("../../assets/img/inscription/bg-section4-circle.png");
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  top: -20%;
  left: -8%;
  @media screen and (max-width: 900px) {
    background-size: contain;
    top: 26%;
    width: 146%;
    left: 1%;
  }
}
.overlay-section8 {
  background-image: url("../../assets/img/inscription/bg-overlay-section7.png");
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0%;
  overflow-x: hidden;
  @media screen and (max-width: 900px) {
    background-size: contain;
    top: 45%;
  }
}
.overlay-section5 {
  background-image: url("../../assets/img/inscription/illustration-section5.png");
  position: absolute;
  z-index: -1;
  width: 40%;
  height: 70%;
  left: 52%;
  background-size: cover;
  background-repeat: no-repeat 0% 0%;
  top: 1%;
  overflow-x: hidden;
  @media screen and (max-width: 900px) {
    background-size: contain;
    background-repeat: no-repeat;
    top: 48%;
    left: 24%;
    width: 51%;
  }
}
.overlay-section6 {
  position: absolute;
  z-index: -1;
  width: 90%;
  height: 100%;
  left: -3%;
  background-repeat: no-repeat 0% 0%;
  top: -28%;
  @media screen and (max-width: 900px) {
    width: 112%;
    height: 40%;
    left: 17%;
    top: 26%;
  }
}
.overlay-section-rocks {
  background-image: url("../../assets/img/inscription/rocks.png");
  position: absolute;
  z-index: -1;
  width: 20%;
  height: 20%;
  left: 70%;
  background-size: cover;
  background-repeat: no-repeat 0% 0%;
  top: 65%;
}
.overlay-section-bg-6 {
  background-image: url("../../assets/img/inscription/bggalaxies.png");
  width: 100%;
  height: 44%;
  background-size: cover;
  background-repeat: no-repeat 0% 0%;
  mix-blend-mode: screen;
  z-index: -2;
}
.title-section2 {
  font-size: 40px !important;
  font-family: "Roboto-bold" !important;
  letter-spacing: 0.28px;
  color: #2df8c0;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  @media screen and (max-width: 900px) {
    font-size: 28px !important;
    padding-top: 12% !important;
  }
}
.slogan-first-talent {
  margin-bottom: -3rem !important;
  font-size: 135px !important;
  animation: an 1s ease-out 1;
  @media screen and (max-width: 1440px) {
    font-size: 100px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 65px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 40px !important;
    padding-top: 30% !important;
  }
}
.slogan-first-planetes {
  padding-top: 6rem;
  padding-bottom: 6rem;
  font-size: 131px !important;
  line-height: 142px;
  animation: an 1s ease-out 1;
  @media screen and (max-width: 1440px) {
    font-size: 100px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 65px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
    line-height: 66px;
  }
}
.section2-h1 {
  font-size: 100px !important;
  color: #ffd94a;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 7% !important ;
  margin-bottom: 5rem !important;
  @media screen and (max-width: 1600px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 30px !important;
    margin-top: 22% !important;
    margin-bottom: 0rem !important;
  }
}

.mission-sesction3 {
  font-size: 120px !important;
  font-family: "Roboto-bold";
  letter-spacing: 0px;
  color: #2df8c0;
  text-transform: uppercase;
  opacity: 1;
  width: 50% !important;
  margin-left: 10% !important;
  line-height: 140px;
  @media screen and (max-width: 1600px) {
    font-size: 90px !important;
    line-height: 92px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 80px !important;
    line-height: 90px;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
    line-height: 50px;
    width: 80% !important;
  }
}
.section-mission4 {
  width: 40% !important;
  margin-left: 10% !important;
  @media screen and (max-width: 900px) {
    width: 83% !important;
  }
}
.description-section5 {
  font-size: 120px !important;
  letter-spacing: 0px;
  color: #ffd94a;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 40%;
  line-height: 140px;
  padding-right: 11% !important;
  padding-bottom: 18px !important;
  font-family: "Roboto-condensed-bold";
  @media screen and (max-width: 1600px) {
    font-size: 90px !important;
    line-height: 92px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 80px !important;
    line-height: 82px;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
    line-height: 50px !important;
    margin-left: 4% !important;
    padding-bottom: 80% !important;
  }
}
.description-section6 {
  font-size: 120px !important;
  letter-spacing: 0px;
  color: #ffd94a;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 40%;
  line-height: 125px;
  padding-right: 10% !important;
  padding-bottom: 18px !important;
  font-family: "Roboto-condensed-bold";
  @media screen and (max-width: 1600px) {
    font-size: 90px !important;
    line-height: 92px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 80px !important;
    line-height: 82px;
    padding-left: 9%;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
    line-height: 50px !important;
    padding-bottom: 79% !important;
    padding-right: 0% !important;
    margin-left: 4% !important;
    padding-left: 0% !important;
  }
}
.description-inscription8 {
  font-family: "Roboto-condensed-bold";
  font-size: 80px !important;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  line-height: 143px;
  text-align: center;
  @media screen and (max-width: 900px) {
    font-size: 28px !important;
    line-height: 37px !important;
  }
}
.title-section5 {
  font-size: 120px !important;
  line-height: 134px;
  letter-spacing: 0px;
  color: #3a3ece;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 10%;
  padding-bottom: 2rem;
  @media screen and (max-width: 1600px) {
    font-size: 90px !important;
    line-height: 92px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 100px !important;
    line-height: 104px;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
    line-height: 50px !important;
    margin-left: 4% !important;
  }
}
.text-section5 {
  text-align: left;
  font-size: 30px !important;
  color: #2df8c0;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 10%;
  padding-bottom: 2rem;
  @media screen and (max-width: 1280px) {
    font-size: 25px !important;
  }
  @media screen and (max-width: 900px) {
    line-height: 24px !important;
    font-size: 20px !important;
    margin-left: 4% !important;
  }
}
.m-left-10 {
  margin-left: 10%;
  line-height: 110% !important;
  @media screen and (max-width: 900px) {
    line-height: 19px !important;
    margin-left: 4% !important;
  }
}
.m-left-15 {
  margin-left: 40%;
  line-height: 110% !important;
  @media screen and (max-width: 1280px) {
    font-size: 23px !important;
    line-height: 29px !important;
    margin-left: 48%;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
    line-height: 19px !important;
    margin-left: 4%;
  }
}
.title-section8 {
  font-size: 153px;
  letter-spacing: 1.07px;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  line-height: 153px;
  font-family: "Roboto-black";
  padding-bottom: 5%;
  @media screen and (max-width: 1600px) {
    padding-top: 20%;
  }
  @media screen and (max-width: 1366px) {
    padding-top: 20%;
  }
  @media screen and (max-width: 1280px) {
    padding-top: 20%;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
    line-height: 61px !important;
    padding-top: 0% !important;
  }
}
.title-partenariat {
  font-size: 100px;
  letter-spacing: 1.07px;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  line-height: 183px;
  font-family: "Roboto-black";
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
    line-height: 50px !important;
  }
}
.text-section7 {
  font-size: 60px !important;
  font-family: "Roboto-condensed";
  letter-spacing: 0px;
  color: #ffd94a;
  text-transform: uppercase;
  opacity: 1;
  line-height: 100%;
  margin-left: 30%;
  @media screen and (max-width: 900px) {
    font-size: 28px !important;
    margin-left: 4% !important;
    width: 71% !important;
  }
}
.text-fin {
  line-height: 100%;
}
.bg-inspirnaute-sec9 {
  background: linear-gradient(180deg, #151b47, #151b47);
  height: 100%;
  position: relative;
  z-index: 0;
  padding: 5% 10% 12% 10%;
  .infini {
    margin-left: 35%;
  }
  .contact {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 1600px) {
    padding: 5% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 5% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1366px) {
    padding: 5% 10% 10% 10% !important;
  }
  @media screen and (max-width: 1280px) {
    padding: 5% 13% 10% 10% !important;
  }
}
.notice a{
  color: #fff;
  text-decoration: none;
}
.notice a:hover{
  cursor: pointer;
  color: #fff;
  opacity: 0.75;
}

