.form-label.label-Filter {
    font-family: "Roboto-regular" !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0px !important;
    color: #1c105d !important;
    opacity: 0.5;
    margin-top: 10px;
}
.align-md {
    @media screen and (max-width: 1600px) {
        display: block !important;
        width: 100% !important;
    }
}
.w-md-100 {
    @media screen and (max-width: 1600px) {
        width: 100% !important;
    }
}
.mb-md-3rem{
    @media screen and (max-width: 1600px) {
        margin-bottom: 3rem !important;
    }
}