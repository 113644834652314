.avatar-image {
  width: 200px !important;
  height: 200px !important;
  // overflow: "hidden";
  // margin-right: 10px !important;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

#profilePic{
  width: 220px;
}

.text-light {
  font-family: "Roboto-light" !important;
  font-size: 24px !important;
  line-height: 25px !important;
  letter-spacing: 0px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1600px) {
    font-size: 20px !important;
    line-height: 22px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 18px !important;
    line-height: 20px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 16px !important;
    line-height: 18px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

.text-campany {
  font-family: "Roboto-medium" !important;
  font-size: 40px !important;
  line-height: 53px !important;
  @media screen and (max-width: 1600px) {
    font-size: 36px;
    line-height: 45px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 38px;
    line-height: 41px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 33px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 30px;
    line-height: 33px;
  }
}

.text-campany-label {
  color: #ffffff;
  font-family: "Roboto-light" !important;
  font-size: 24px;
  line-height: 26px;
  font-weight: 300 !important;
  margin-bottom: 20px !important;
  @media screen and (max-width: 1600px) {
    font-size: 22px;
    line-height: 24px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 20px;
    line-height: 22px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 16px;
    line-height: 18px;
  }
}

.text-resume {
  font-family: "Roboto-light" !important;
  font-size: 23px !important;
  line-height: 25px !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
    line-height: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
    line-height: 16px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 12px !important;
    line-height: 15px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 23px !important;
    line-height: 10px !important;
  }
}

.text-pourcentage {
  text-align: left;
  font-family: "Roboto-regular";
  font-size: 22px !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 10px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 22px !important;
  }
}
.pourcentage {
  text-align: left;
  font-family: "Roboto-light";
  font-size: 66px !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1600px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 30px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 30px !important;
  }
}

.astro-image {
  width: 200px;
  @media screen and (max-width: 1600px) {
    width: 150px !important;
  }
  @media screen and (max-width: 1366px) {
    width: 150px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 90px !important;
  }
  @media screen and (max-width: 1280px) {
    width: 150px !important;
  }
}

.text {
  text-align: justify !important;
  font-family: "Roboto-regular" !important;
  color: #000000 !important;
  font-size: 16px;
  span {
    font-family: "Roboto-bold" !important;
    color: #000000;
  }
}
.name {
  font-family: "Roboto-light" !important;
  font-size: 30px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: left;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 30px;
  }
}

.place {
  font-family: "Roboto-light";
  font-size: 25px !important;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  @media screen and (max-width: 1366px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px !important ;
  }
  @media screen and (max-width: 1280px) {
    font-size: 18px !important;
  }
}

.text-professionl {
  font-family: "Roboto-medium";
  font-size: 25px !important;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;

  @media screen and (max-width: 1366px) {
    font-size: 17px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 14px !important;
  }
  span {
    font-family: "Roboto-regular" !important;
    font-size: 25px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;

    @media screen and (max-width: 1366px) {
      font-size: 17px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px !important ;
    }
    @media screen and (max-width: 1280px) {
      font-size: 14px !important;
    }
  }
}

.text-inspirnaute {
  font-family: "Roboto-regular";
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
}

.card-remuse {
  border: none !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  padding: 10px 10px 10px 10px !important;
}

.title-resume {
  font-family: "Roboto-bold";
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: #0f1447 !important;
  opacity: 1;
  span {
    font-family: "Roboto-regular";
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    color: #0f1447 !important;
    opacity: 1;
  }
  p {
    font-family: "Roboto-regular";
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    color: #0f1447 !important;
    opacity: 1;
  }
}
.title-resume-company {
  font-family: "Roboto-regular";
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #0f1447 !important;
  opacity: 1;
}
.date-work {
  font-family: "Roboto-regular";
  font-size: 16px;
  padding-left: 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #828396 !important;
  opacity: 1;
}
.description-profile {
  font-family: "Roboto-regular";
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #434343 !important;

  opacity: 1;
}
.skills {
  padding: 2px;
  span {
    padding-bottom: 2px;
    font-family: "Roboto-medium";
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    color: #0f67d9;
    opacity: 1;
  }
  p {
    padding: 1px;
    font-family: "Roboto-regular";
    font-size: 16px;
    text-align: left;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
  }
}
.language {
  font-family: "Roboto-medium";
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #434343 !important;
  opacity: 1;
}

.mt-10 {
  margin-top: 5% !important;
  @media screen and (max-width: 1366px) {
    margin-top: 6% !important;
  }
  @media screen and (max-width: 1280px) {
    margin-top: 7% !important;
  }
}
.period-edit {
  font-family: "Roboto-regular";
  font-size: 16px;
  text-align: left;

  letter-spacing: 0px;
  color: #1c105d;
  opacity: 1;
}
hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background-color: #000000 !important;
  color: #000000;
}

// timeline resume
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #c7c9e2;
  display: inline-block;
  position: absolute;
  left: 7px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 0px;
}
ul.timeline > li:before {
  content: " ";
  background: #1d268c;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #1d268c;
  left: 2px;
  width: 14px;
  height: 14px;
  z-index: 400;
}

.color-record {
  background-color: #e94545 !important;
  border-color: #e94545 !important;
  font-weight: bold;
}

.margin-right-experience {
  margin-right: 75px;
}

.text-regular {
  font-family: "Roboto-regular";
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.text-roboto-bold {
  font-family: "Roboto-bold";
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.text-roboto-bold.blue,
.text-regular.blue {
  color: #0f1447;
}

.text-regular.red {
  color: #e94545;
}

.landing-place {
  font-size: 20px !important;
  color: #ffffff;
  opacity: 0.5;
  font-family: "Roboto-regular";
  @media screen and (max-width: 1600px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px !important;
  }
}

.landing-place-petit {
  font-size: 16px !important;
  color: #ffffff;
  opacity: 0.5;
  font-family: "Roboto-regular";
  @media screen and (max-width: 1600px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 11px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 11px !important;
  }
}

.position-cosmonaute {
  font-family: "Roboto-regular";
  font-size: 22px !important;
  color: #ffffff !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px !important;
  }
}

.mb-3.input-group{
  margin-left: 10px;
}

.title-profile-cosmonaute {
  text-align: left !important;
  font-family: "Roboto-regular" !important;
  font-size: 17px !important;
  line-height: 2px !important;
  letter-spacing: 0px !important;
  color: #1d268c !important;
  opacity: 1 !important;
  margin-bottom: 20px !important;
}

.display-button-header {
  @media screen and (max-width: 700px) {
    margin-top: 3%  !important;
    display: contents !important;
  }
}
