/* mixins */
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

.rotate-90 {
    @include rotate(-90deg);
}

.rotate90 {
    @include rotate(90deg);
}
