.form-select.input-filter,
.form-control.input-filter {
    border: 1px solid #e2e2e2a5 !important;
    border-radius: 4px !important;
    font-family: "Roboto-regular" !important;
    font-size: 14px !important;
    letter-spacing: 0px !important;
    color: rgb(177, 177, 177, 0.7) !important;
    svg {
        color: #1C105D !important;
    }
}
