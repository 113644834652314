.terms {
  background: linear-gradient(180deg, #19002a, #2e1ca5);
  height: 100%;
  min-height: 100vh;
  z-index: -2;
  .padding-titre {
    padding-top: 8%;
  }
  .terms-planet1 {
    width: 4%;
    position: absolute;
    left: 12%;
    top: 31%;
  }
  .terms-planet2 {
    width: 12%;
    position: absolute;
    left: 80%;
    top: 41%;
  }
  .terms-planet3 {
    width: 12%;
    position: absolute;
    left: -2%;
    top: 64%;
  }
  .stars {
    background-image: url("../../assets/img/inscription/Starsterms.png");
    mix-blend-mode: screen;
    // background-size: cover;
    position: absolute;
    width: 100%;
    top: 80%;
    height: 35%;
    z-index: 0;
    background-repeat: no-repeat 0% 0%;
  }
  .etoile {
    position: absolute;
    z-index: 1;
    top: 20%;
    height: 70%;
    width: 100%;
  }
  .padding-icon {
    padding-top: 15% !important;
  }
}
.conditions {
  background: linear-gradient(180deg, #2e1ca5, #329de4);
  height: 100%;
  min-height: 115vh;
  @media screen and (max-width: 1600px) {
    min-height: 158vh;
    padding-top: 5%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 135vh;
    padding-top: 5%;
  }
  @media screen and (max-width: 1280px) {
    min-height: 130vh;
    padding-top: 5%;
  }
  .line-text {
    margin-bottom: -0.5rem !important;
  }
  .margin-section {
    padding-left: 8%;
    padding-right: 8%;
  }
  .astornaute {
    width: 25%;
    top: 150%;
    left: 75%;
    position: absolute;
    @media screen and (max-width: 1600px) {
      top: 217%;
      height: 43%;
    }
    @media screen and (max-width: 1600px) {
      top: 175%;
      height: 43%;
    }
  }
}
.conditions-page1 {
  background: linear-gradient(180deg, #329de4, #329de4);
  height: 100%;
  min-height: 115vh;
  @media screen and (max-width: 1600px) {
    min-height: 158vh;
  }
  @media screen and (max-width: 1366px) {
    min-height: 135vh;
  }
  @media screen and (max-width: 1280px) {
    min-height: 130vh;
  }
  .line-text {
    margin-bottom: -0.5rem !important;
  }
  .margin-section {
    padding-left: 8%;
    padding-right: 8%;
  }
  .astornaute {
    width: 25%;
    top: 150%;
    left: 75%;
    position: absolute;
    opacity: 0.5;
    @media screen and (max-width: 1600px) {
      top: 217%;
      height: 43%;
    }
    @media screen and (max-width: 1600px) {
      top: 175%;
      height: 43%;
    }
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
.conditions-page2 {
  background: linear-gradient(180deg, #329de4, #329de4);
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 1600px) {
    min-height: 127vh;
  }
  @media screen and (max-width: 1366px) {
    min-height: 126vh;
  }
  @media screen and (max-width: 1280px) {
    min-height: 126vh;
  }

  .groupe-legal {
    background-image: url("../../assets/img/inscription/Groupe.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 35vh;
    background-position: center;
    background-size: 103% 102%;
  }
  .line-text {
    margin-bottom: -0.5rem !important;
  }
  .margin-section {
    padding-left: 8%;
    padding-right: 8%;
  }
  .astornaute {
    width: 25%;
    top: 150%;
    left: 75%;
    position: absolute;
    @media screen and (max-width: 1600px) {
      top: 217%;
      height: 43%;
    }
    @media screen and (max-width: 1600px) {
      top: 175%;
      height: 43%;
    }
  }
}
.conditions-page3 {
  background: linear-gradient(180deg, #329de4, #2e1ca5);
  height: 100%;
  min-height: 115vh;
  @media screen and (max-width: 1600px) {
    min-height: 158vh;
    padding-top: 5%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 135vh;
    padding-top: 5%;
  }
  @media screen and (max-width: 1280px) {
    min-height: 130vh;
    padding-top: 5%;
  }
  .line-text {
    margin-bottom: -0.5rem !important;
  }
  .margin-section {
    padding-left: 8%;
    padding-right: 8%;
  }
  .astornaute {
    width: 25%;
    top: 150%;
    left: 75%;
    position: absolute;
    @media screen and (max-width: 1600px) {
      top: 217%;
      height: 43%;
    }
    @media screen and (max-width: 1600px) {
      top: 175%;
      height: 43%;
    }
  }
}
.conditions-page4 {
  background: linear-gradient(180deg, #2e1ca5, #2e1ca5);
  height: 100%;
  min-height: 115vh;
  @media screen and (max-width: 1600px) {
    min-height: 158vh;
    padding-top: 5%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 135vh;
    padding-top: 5%;
  }
  @media screen and (max-width: 1280px) {
    min-height: 130vh;
    padding-top: 5%;
  }
  .line-text {
    margin-bottom: -0.5rem !important;
  }
  .margin-section {
    padding-left: 8%;
    padding-right: 8%;
  }
  .astornaute {
    width: 25%;
    top: 150%;
    left: 75%;
    position: absolute;
    @media screen and (max-width: 1600px) {
      top: 217%;
      height: 43%;
    }
    @media screen and (max-width: 1600px) {
      top: 175%;
      height: 43%;
    }
  }
}
.pg-terms {
  background: linear-gradient(180deg, #151b47, #151b47);
  height: 100%;
  position: relative;
  z-index: -1;
  padding: 5% 10% 12% 10%;
  .infini {
    margin-left: 35%;
  }
  .contact {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 1600px) {
    padding: 5% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 10% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1366px) {
    padding: 15% 10% 10% 10% !important;
  }
  @media screen and (max-width: 1280px) {
    padding: 6% 13% 10% 10% !important;
  }
  .overlay {
    background-image: url("../../assets/img/inscription/overlay-propos6.png");
    position: absolute;
    z-index: -1;
    width: 22%;
    height: 30%;
    left: 75%;
    background-size: contain;
    background-repeat: no-repeat;
    top: 62%;
  }
}
