/* The following block can be included all class of margin style */
.mr-20 {
  margin-right: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-big {
  margin-left: 140px;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mt-10-percent {
  margin-top: 10%;
  @media screen and (max-width:700px) {
    line-height: 10% !important;
    margin-top: 10% !important;
  }
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-7-percent {
  margin-top: 7% !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.margin-left-60 {
  margin-left: 60px !important;
  padding: 10px;
}
