.tag{
  display: flex;
  align-items: center;
  position: relative;
}

.text-length.pink,.text-length.green,.text-length.blue{
  right: 12px;
  top: 2px;
  position: absolute;}

.lilCard{
  margin-top: 25px !important;
}

.text-Complete {
  font-family: "Roboto-regular" !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: 0px;
  color: rgb(255, 255, 255, 0.49);
}

.card.card-recruiter {
  border: 1px solid #434cb47a !important;
  border-radius: 14px !important;
  background-color: #0e10335c !important;
  height: 100%;
  width: 100%;
}
.card.card-dashboard {
  border: 2px solid #434cb47a !important;
  border-radius: 14px !important;
  background-color: transparent !important;
  transition: transform 0.3s;
  height: 48% !important;
  width: 100% !important;
}
.card.card-dashboard-cosmonaute {
  border: 2px solid #434cb47a !important;
  border-radius: 14px !important;
  background-color: transparent !important;
  transition: transform 0.3s;
  height: 48% !important;
  width: 100% !important;
}
.card.card-dashboard-cosmonaute:hover {
  background-color: #0e10335c !important;
  transform: scale(1.05);
}
.card.card-dashboard:hover {
  background-color: #0e10335c !important;
  cursor: pointer !important;
  transform: scale(1.05);
}

.card.card-redirection {
  border: 2px solid #434cb47a !important;
  border-radius: 14px !important;
  background-color: transparent !important;
  transition: transform 0.3s;
  height: 100% !important;
  width: 100% !important;
  // background-color: #0e10335c !important;
}
.card.card-redirection:hover {
  background-color: #0e10335c !important;
  cursor: pointer !important;
  transform: scale(1.05);
}

.card.card-blue {
  border-radius: 14px !important;
  background-color: #0f67d9 !important;
  height: 100%;
  width: 100%;
}
.card.card-content {
  background-color: #ffffff !important;
  border: 1px solid !important;
  border-color: #e2e2e2a6 !important;
  border-radius: 8px !important;
}

.card-label {
  font-family: "Roboto-bold" !important;
  font-size: 30px !important;
  line-height: 36px !important;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  @media screen and (max-width: 1600px) {
    font-size: 27px !important;
    line-height: 30px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 25px !important;
    line-height: 27px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px !important;
    line-height: 25px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 20px !important;
    line-height: 23px !important;
  }
}

.title-label {
  font-family: "Roboto-medium" !important;
  font-size: 20px !important;
  letter-spacing: 0px;
  color: #1d268c;
  @media screen and (max-width: 1600px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 24px !important;
  }
}

.title-label.pink {
  color: #d52bff !important;
}

.title-label.green {
  color: #2c9e7f !important;
}

.text-header-interview {
  font-family: "Roboto-bold" !important;
  font-size: 20px !important;
  letter-spacing: 0px !important;
  color: #0f1447 !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 10px !important;
  }
}

.text-mission-Number {
  text-align: left;
  font-family: "Roboto-medium" !important;
  font-size: 18px !important;
  line-height: 61px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
    line-height: 59px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px !important;
    line-height: 57px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px !important;
    line-height: 55px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: center !important;
  }
}

.text-Number {
  text-align: left;
  font-family: "Roboto-bold" !important;
  font-size: 60px !important;
  line-height: 81px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  // margin-bottom: 30px !important;
  @media screen and (max-width: 1600px) {
    font-size: 50px !important;
    line-height: 51px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 40px !important;
    line-height: 41px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 30px !important;
    line-height: 31px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 30px !important;
    line-height: 37px !important;
    text-align: center !important;
  }
}

.card-detail {
  display: flex;
  .color-agenda {
    color: #828396 !important;
  }
  i {
    color: #828396 !important;
  }
  i.i-agenda {
    font-size: 13px !important;
  }
  i.i-pin {
    font-size: 16px !important;
  }
  p {
    font-family: "Roboto-regular" !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0px !important;
    color: #828396 !important;
    margin-top: 2px !important;
  }
}

.mr-5 {
  margin-right: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.blockPlusMission {
  border-radius: 50%;
  padding: 27%;
  background: #2c79de;
  float: right;
  transition: transform 0.3s;
  font-size: 30px !important;
  margin-left: 3% !important;
}

.blockPlusMission:hover {
  background-color: #0e10335c !important;
  cursor: pointer !important;
  transform: scale(1.1);
}

.pr-3 {
  padding-right: 3% !important;
}

.pl-3 {
  padding-left: 3% !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.text-Mission {
  color: rgb(255, 255, 255, 0.8);
  font-family: "Roboto-regular" !important;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  @media screen and (max-width: 1600px) {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 10px !important;
    line-height: 14px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

.text-form {
  color: rgb(255, 255, 255);
  font-family: "Roboto-italic" !important;
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: 0px !important;
  i {
    margin-left: 20px !important;
    font-size: 11px !important;
  }
  @media screen and (max-width: 1600px) {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 10px !important;
    line-height: 12px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 8px !important;
    line-height: 10px !important;
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}

.margin-top-avatar {
  @media screen and (max-width: 700px) {
    margin-top: 0.5rem !important;
  }
}
.display-mobile-logo {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.btn {
 @media screen and (max-width: 700px) {
  border: 7px solid transparent !important;
 }
}
.p-image-18 {
    padding: 18% !important;
    @media screen and (max-width: 1280px) {
      padding: 30% !important;
    }
}