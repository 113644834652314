.text-nothing {
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    letter-spacing: 0px !important;
    color: rgb(15, 20, 72, 0.5) !important;
    @media screen and (max-width: 1600px) {
        font-size: 16px !important;
    }
    @media screen and (max-width: 1366px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 12px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 10px !important;
    }
}
