// start Form Style
.error_feild {
  font-family: "Roboto-regular" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  letter-spacing: 0px !important;
  color: #d30909 !important;
  margin-bottom: unset !important;
  @media screen and (max-width: 1600px) {
    font-size: 13px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 11px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 10px !important;
  }
}
.input-group {
  .form-control {
    margin-bottom: unset !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}
.form-select,
.form-control,
.css-1s2u09g-control,
.css-1pahdxg-control {
  -webkit-border-radius: unset !important;
  -moz-border-radius: unset !important;
  border-radius: unset !important;
  border-color: #ced4da !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  @media screen and (max-width: 1600px) {
    margin-bottom: 17px !important;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: 16px !important;
  }
  @media screen and (max-width: 1280px) {
    margin-bottom: 14px !important;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 12px !important;
  }
}
.form-control:focus {
  border-color: #ced4da !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.form-select:focus {
  border-color: #ced4da !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.form-control.input-header {
  background-color: transparent !important;
  border-color: rgb(255, 255, 255, 0.48) !important;
  color: rgb(255, 255, 255, 1) !important;
}
.form-select.input-header {
  background-color: transparent !important;
  border-color: rgb(255, 255, 255, 0.48) !important;
  color: rgb(255, 255, 255, 1) !important;
}
.form-control.error {
  border-color: #d30909 !important;
}
.form-select.error {
  border-color: #d30909 !important;
}
.css-1pahdxg-control.error {
  border-color: #d30909 !important;
}
.form-label {
  font-family: "Roboto-regular" !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0px !important;
  color: #8e89b3 !important;
  // margin-top: 20px !important;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 1 !important;
}
.form-check-input:disabled {
  opacity: 1 !important;
}
// End Form Style
.form-control.input-filter {
  border: 1px solid #e2e2e2a5 !important;
  border-radius: 4px !important;
  font-family: "Roboto-regular" !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #817d7d !important;
  height: 35px;
}
.document-th-40 {
  width: 40% !important;
}
.document-th-30 {
  width: 30% !important;
}
.margin-bottom-th {
  margin-bottom: 1.5rem !important;
  @media screen and (max-width: 1280px) {
    margin-bottom: 0.35rem !important;
  }
}
textarea#subject {
  @media screen and (max-width: 1280px) {
    max-height: 170px;
  }
}
textarea#reason {
  @media screen and (max-width: 1280px) {
    max-height: 170px;
  }
}
/* Date picker start css */
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      border: solid 1px #ced4da !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      width: 100% !important;
      padding: 5px !important;
    }
    input:focus,
    input:focus-visible {
      border-color: #ced4da !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
    input.error {
      border-color: #d30909 !important;
    }
  }
}
/* Date picker end css */
/* Select start css */
div.error {
  border-color: #d30909 !important;
}
/* Select end css */
