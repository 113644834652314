.card-container {
  height: 420px !important;
  width: 100%;
  border: 2px solid #e2e2e2 !important;
  border-radius: 8px;
  margin: 0 2% 0 2%;
  padding: 0 2% 3%;
@media screen and (max-width: 768px) {
  height: auto !important;
}
}
.card-container-line{

  width: 100%;
  border: 2px solid #e2e2e2 !important;
  border-radius: 8px;
  margin: 0 2% 0 2%;
  padding: 0 2% 3%;

}
.dashboard-table {
  border-color: #e2e2e2a6;
}
.card-Doughnut {
  height: 60% !important;
  width: 60% !important;
  margin-top: 5%;
}
.header-text {
  color: #828396;
  text-align: left;
  font-family: Roboto;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  border-color: #e2e2e2a6 !important;
}
.key-color {
  color: #d52bff;
}
.body-text {
  text-align: left;
  font-family: Roboto;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 18px;
}
.text-card {
  font-family: "Roboto-bold" !important;
  font-size: 20px !important;
  line-height: 25px !important;
  letter-spacing: 0px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  align-self: center;
  margin-bottom: 0 !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
    line-height: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px !important;
    line-height: 16px !important;
  }

  @media screen and (max-width: 1280px) {
    font-size: 14px !important;
    line-height: 10px !important;
  }
}
.cards-dashborad {
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
}
.text-number-cosmo {
  font-family: "Roboto-bold";
  font-size: 60px;
  letter-spacing: 0px;
  margin-left: 10% !important;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1280px) {
    margin-left: 0% !important;
  }
  @media screen and (max-width: 1240px) {
    font-size: 45px;
    margin-left: 0% !important;
  }
}
.text-title-header {
  font-family: "Roboto-bold";
  font-size: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1240px) {
    font-size: 20px;
    margin-left: 8% !important;
  }
}
.text-header-dashboard {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff !important;
  font-size: 24px;
  opacity: 0.49;
  @media screen and (max-width: 1240px) {
    font-size: 18px;
    margin-left: 8% !important;
  }
}
.text-cosmo {
  font-family: "Roboto";
  font-size: 30px;
  letter-spacing: 0px;
  margin-left: 10% !important;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  @media screen and (max-width: 1240px) {
    font-size: 24px;
    margin-left: 3% !important;
  }
}
.margin-header-admin {
  margin-bottom: -2rem !important;
}
.margin-div1 {
  margin-bottom: -2% !important;
  @media screen and (max-width: 1280px) {
    margin-bottom: 0% !important;
  }
}
.margin-div2 {
  margin-bottom: -9% !important;
}
.d-flex-blcok-candisates {
  flex-direction: column !important;

}
.col-12-dashbord-detail {
  @media screen and (max-width: 1280px) {
    .col-lg-12 {
      width: 100% !important;

    }
  }
  @media screen and (max-width: 700px) {
    .col-lg-12 {
      width: 100% !important;
      margin-left: 0% !important;
    }
  }
}
.col-12-dashbord-detail-right {
  @media screen and (max-width: 1280px) {
    .col-lg-12 {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 700px) {
    .col-lg-12 {
      width: 100% !important;
    }
  }
}
.d-flex-warp-header-admin {
  @media screen and (max-width: 900px) {
    flex-flow: wrap !important ;
  }
  @media screen and (max-width: 768px) {
    margin-top: 5% !important;
  }
}
.padding-block-add-header {
  padding-top: 30% !important;
  padding-bottom: 10% !important;
  @media screen and (max-width: 1280px) {
    padding-top: 10% !important;
  }
  @media screen and (max-width: 900px) {
    padding-top: 4% !important;
  }
}
.width-100 {
  width: 100% !important;
  @media screen and (max-width: 900px) {
    width: 60% !important;
    text-align: center !important;
  }
}
