
$bigSize:30px;
.LoginForm{
  border-radius: 20px !important;
}

.title{
  font-size: $bigSize !important;
  font-weight: bold !important;

}
.formGroup{
  color: #1C105D;
 font-size: 20px;
}

.LoginSubmitBtn{
  min-width: 180px !important;
  min-height: 56px !important;
  border-radius: 30px !important;
}
.forget-pass{
  font-size: 14px !important;
  text-decoration: none;
}
.validationText{
  font-size: 13px ;

}
.loginInput{
  height: 50px !important;
}
.LoginFormFooter {
 background-color: #E7E8E9 !important;
  border-radius: 0 0 20px 20px !important;
  height: 70px;
  border-top: 1px solid #E7E8E9 !important;

}

@media screen and (max-width: 440px ) {
  .formSection{
    padding: 10% 0 0 0 !important;
  }
  .LoginForm{
    margin-top: 40px !important;
  }
}

@media screen and (min-width: 440px ) {
  .formSection{
    padding: 10% 5% 0 5% !important;
  }
}

