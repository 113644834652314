.table {
    margin-bottom: 0 !important;
}
.table-content {
    font-family: "Roboto-regular" !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    text-align: left !important;
    @media screen and (max-width: 1600px) {
        font-size: 13px !important;
        line-height: 15px !important;
    }
    @media screen and (max-width: 1366px) {
        font-size: 12px !important;
        line-height: 14px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 11px !important;
        line-height: 13px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 10px !important;
        line-height: 11px !important;
    }
}

.table-Header {
    font-family: "Roboto-bold" !important;
    font-size: 15px !important;
    line-height: 17px !important;
    letter-spacing: 0px !important;
    color: #828396 !important;
    text-align: left !important;
    opacity: 1 !important;
    @media screen and (max-width: 1600px) {
        font-size: 14px !important;
        line-height: 16px !important;
    }
    @media screen and (max-width: 1366px) {
        font-size: 13px !important;
        line-height: 15px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 12px !important;
        line-height: 14px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 11px !important;
        line-height: 13px !important;
    }
}

.table-padding {
    td {
        margin: 5% !important;
        vertical-align: middle !important;
    }
    label,
    div,
    p {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    div.row,
    div.date-interview,
    div.proposed-Profile,
    div.title-mission,
    div.title-candidat-name,
    span.text-tag {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    div.margin-unset {
        margin-top: unset !important;
        margin-bottom: unset !important;
    }
}

.btn-detail {
    font-family: "Roboto-regular" !important;
    font-size: 20px !important;
    letter-spacing: 0px !important;
    opacity: 1;
    @media screen and (max-width: 1600px) {
        font-size: 18px !important;
    }
    @media screen and (max-width: 1366px) {
        font-size: 16px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 12px !important;
    }
}

.btn-detail.gray {
    color: #828396 !important;
}

.btn-detail.green {
    color: #2c9e7f !important;
}

.btn-detail.red {
    color: #e22e2e !important;
}

.btn-detail.blue {
    color: #0f1447 !important;
}

.searsh-Input {
    color: #000000 !important;
    top: 30%;
    right: 3%;
    font-size: 14px;
}

.link {
    text-decoration: underline !important;
}
.link:hover {
    cursor: pointer !important;
    color: #0f67d9 !important;
    font-weight: bold !important;
}
