.avatar-profil {
    border: solid 2px #9975e1 !important;
    background-color: #9975e1 !important;
    border-radius: 50%;
    width: 200px !important;
    height: 200px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "Roboto-bold" !important;
    font-size: 70px !important;
    @media screen and (max-width: 1600px) {
        width: 180px !important;
        height: 180px !important;
        font-size: 65px !important;
    }
    @media screen and (max-width: 1366px) {
        width: 160px !important;
        height: 160px !important;
        font-size: 60px !important;
    }
    @media screen and (max-width: 1280px) {
        width: 140px !important;
        height: 140px !important;
        font-size: 55px !important;
    }
    @media screen and (max-width: 900px) {
        width: 120px !important;
        height: 120px !important;
        font-size: 45px !important;
    }
}

.save-button {
    margin: 3px;
}

.text-back {
    font-family: "Roboto-light" !important;
    font-size: 24px !important;
    line-height: 29px !important;
    letter-spacing: 5.62px !important;
}

.text-landing-place {
    font-family: "Roboto-light" !important;
    font-size: 22px !important;
    line-height: 29px !important;
    letter-spacing: 0px !important;
    color: #ffffff;
    opacity: 1;
}

.text-skill-label {
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    color: #434343;
    opacity: 1;
}

.rating-style {
    font-size: 20px !important;
}

#logo-cosmonaute {
    @media screen and (max-width: 992px) {
        padding: 5% !important;
    }
    @media screen and (max-width: 425px) {
        padding: 8% !important;
    }
}

.link-CV:hover {
    text-decoration: underline !important;
}
