.form-inscrit {
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: "roboto-regualr";
  letter-spacing: 0px;
  color: #1c105d !important;
  opacity: 1;
}
.form-label-inscrit{
  text-align: left;
  font-size: 20px !important;
  letter-spacing: 0px !important;
  color: #434343 !important;
  opacity: 1 !important;
}
.conditions-text {
  text-decoration: underline;
  text-decoration-color: blue;
  color: blue;
  cursor: pointer;
}
.select-option-color {
  color: rgb(0 0 0 / 54%) !important
}
$default-color: #444;
.form-item__element {
  display: inline-block;
  padding: 0.5em;
  vertical-align: middle;
  background: #fff;
  border-radius: 4px;
  border: 1px solid gray; 
}
.form-item__element--select {
  appearance: none;
  padding-right: 2em;
  &:invalid {
    color: gray !important;
  }
  [disabled] {
    color: gray !important;
  }
  option {
    color: $default-color;
  }
}
.margin-poste {
  @media screen and (max-width:1280px) {
    margin-top: 7% !important;
  }
}