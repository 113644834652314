.contact-section1 {
  background: linear-gradient(180deg, #19002a, #272eaf, #329de4);
  height: 100%;
  position: relative;
  z-index: 0;
  padding-bottom: 9%;
  overflow: hidden;
  @media screen and (max-width: 1280px) {
    padding-bottom: 11% !important;
  }
  .line-height-description {
    @media screen and (max-width:900px) {
      font-size: 30px !important;
      line-height: 34px !important;
    }
  }
  .padding-top-title {
    @media screen and (max-width:900px) {
      padding-top: 26% !important;
    }
  }
  .scroll-padding {
    @media screen and (max-width:900px) {
      padding-bottom: 21% !important;
    }
  }
}
.contact-section2 {
  background: linear-gradient(180deg, #329de4, #329de4, #5427af, #19002a);
  height: 150%;
  min-height: 110vh;
  position: relative;
  z-index: 0;
  @media screen and (max-width: 1600px) {
    padding-bottom: 8%;
  }
}
.overlay-section1 {
  background: url("../../assets/img/inscription/overlay-contact1.png");
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  height: 81%;
  width: 100%;
  top: 20%;
  left: 0%;
  @media screen and (max-width: 1600px) {
    height: 79%;
    width: 100%;
    top: 19%;
  }
  @media screen and (max-width: 1366px) {
    height: 79%;
    width: 100%;
    top: 19%;
  }
  @media screen and (max-width: 1280px) {
    height: 75% !important;
    width: 100%;
    top: 19%;
  }
}
.fontSize-110 {
  font-size: 110px !important;
  letter-spacing: 0px;
  line-height: 110px;
  @media screen and (max-width: 1600px) {
    font-size: 80px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 80px !important;
    line-height: 80px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 70px !important;
    line-height: 85px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 40px !important;
  }
}
.description-contact1 {
  opacity: 0.85;
  color: #ffffff;
  text-align: center;
}
.overlay-contacts2 {
  background: url("../../assets/img/inscription/overlay-contact2.png");
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 60%;
  top: 51%;
  left: 0%;
  z-index: -5;
  @media screen and (max-width: 1600px) {
    width: 100%;
    height: 64%;
    top: 67%;
  }
  @media screen and (max-width: 1366px) {
    width: 100%;
    height: 57%;
    top: 67%;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
    height: 51%;
    top: 67%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: 15%;
    top: 87%;
  }
}
.pg-contact-sec5 {
  background: linear-gradient(180deg, #151b47, #151b47);
  height: 100%;
  position: relative;
  z-index: 0;
  padding: 10% 10% 12% 10%;
  .infini {
    margin-left: 35%;
  }
  .contact {
    margin-bottom: 5%;
  }
  .line-height-row {
    @media screen and (max-width:900px) {
      line-height: 64px !important;
    }
  }
  .margin-bottom-contact {
    margin-bottom: 5rem !important;
    @media screen and (max-width:900px) {
      margin-bottom: 0% !important;
    }
  }
  @media screen and (max-width: 1600px) {
    padding: 20% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 10% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1366px) {
    padding: 20% 10% 5% 10% !important;
  }
  @media screen and (max-width: 1280px) {
    padding: 20% 10% 5% 10% !important;
  }
}
.overlay-contact6 {
  background-image: url("../../assets/img/inscription/overlay-propos6.png");
  position: absolute;
  z-index: -1;
  width: 22%;
  height: 30%;
  left: 80%;
  background-size: contain;
  background-repeat: no-repeat;
  top: 62%;
}
.overlay-plane {
  background: url("../../assets/img/inscription/overlay-plane.png");
  background-size: contain;
  position: absolute;
  width: 29%;
  height: 38%;
  top: 3%;
  left: 62%;
  z-index: -5;
  background-repeat: no-repeat;
  @media screen and (max-width: 1280px) {
    width: 36%;
    height: 36%;
    top: 3%;
    left: 60%;
  }
}
.moon {
  background: url("../../assets/img/inscription/moon.png");
  background-size: contain;
  position: absolute;
  width: 15%;
  height: 22%;
  top: 22%;
  left: 92%;
  z-index: -5;
}
.stars {
  background: url("../../assets/img/inscription/starts.png");
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 10%;
  top: 85%;
  left: 0%;
  mix-blend-mode: screen;
  z-index: -8;
}
.stars1 {
  background: url("../../assets/img/inscription/satrtcoupe.png");
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
  width: 100%;
  height: 60%;
  top: 0%;
  left: 0%;
  mix-blend-mode: screen;
  z-index: -11;
}
.overlay-contact {
  background: url("../../assets/img/inscription/overlay-new.png");
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 60%;
  top: 0%;
  left: 0%;
  z-index: -12;
  background-repeat: no-repeat;
}
.contact-title {
  text-align: center;
  font-family: "Roboto-bold";
  font-size: 30px;
  line-height: 37px;
  letter-spacing: 3.93px;
  color: #9d32e4;
  text-transform: uppercase;
  opacity: 1;
}
.contact-form {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 20px;
  opacity: 1;
  align-items: center;
  margin-left: 15% !important;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
  padding-top: 2%;
  z-index: 555;
  @media screen and (max-width:900px) {
    margin-left: 0% !important;
  }
  .form-control{
    opacity: 0.6;
  }
  .form-control-new{
    height: 45px
  }
}
.button-send {
  background: #0f67d9 0% 0% no-repeat padding-box;
  border-radius: 30px !important;
  opacity: 1;
  font-family: "Roboto-bold";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  padding-left: 10% !important;
  padding-right: 10% !important;
}
.fontSize-contact-140 {
  font-size: 140px !important;
  line-height: 125px;
  @media screen and (max-width: 1600px) {
    font-size: 110px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 90px !important;
    line-height: 90px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 70px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 50px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 140px !important;
    line-height: 120px !important;
  }
}
.notice a{
  color: #fff;
  text-decoration: none;
}
.notice a:hover{
  cursor: pointer;
  color: #fff;
  opacity: 0.75;
}