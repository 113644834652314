.modal-Detail-Title {
    font-family: "Roboto-regular" !important;
    font-size: 14px !important;
    letter-spacing: 0px !important;
    color: #1d268c !important;
    opacity: 1 !important;
}

.modal-Detail-Value {
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    text-align: left !important;
}

.css-iljtu2-MuiRating-root.Mui-disabled {
    opacity: 1 !important;
}
