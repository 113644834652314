.arrow-up-scroll {
    background-color: #f4c935 !important;
    opacity: 0.7 !important;
    -webkit-border-radius: 100% !important;
    -moz-border-radius: 100% !important;
    border-radius: 100% !important;
    width: 50px !important;
    height: 50px !important;
    text-align: center !important;
    -webkit-transform: rotate(180deg) !important;
    -moz-transform: rotate(180deg) !important;
    -ms-transform: rotate(180deg) !important;
    -o-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
    color: #1b0945 !important;
    position: fixed !important;
    right: 50px !important;
    top: 89% !important;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -moz-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -moz-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    z-index: 99999999 !important;
}
.arrow-up-scroll::before {
    font-size: 14px !important;
}
.arrow-up-scroll:hover {
    background-color: #1b0945 !important;
    color: #f4c935 !important;
}
