.paginate {
    ul {
        display: flex;
        padding-left: 0;
        padding-top: 1rem;
        list-style: none;
        li {
            a {
                font-family: "Roboto-bold";
                font-size: 14px;
                margin-right: 5px;
                color: #1c205d !important;
                border-radius: 4px;
                border: 1px solid #dddde9;
                padding: 5px 12px 5px 12px;
                text-decoration: none !important;
            }
            a:hover {
                border: 1px solid #0f67d9 !important;
                color: #0f67d9 !important;
                cursor: pointer !important;
            }
        }
        li.previous,
        li.next {
            a {
                border: 1px solid #b1b1b1 !important;
                color: #b1b1b1 !important;
            }
        }
        li.active a {
            border: 1px solid #0f67d9 !important;
            color: #0f67d9 !important;
            cursor: pointer !important;
        }
        li.disable,
        li.disabled {
            a {
                border: 1px solid rgb(145, 158, 171, 0.5) !important;
                background-color: rgb(145, 158, 171, 0.5) !important;
                color: #fff !important;
                cursor: default !important;
            }
            a:hover {
                border: 1px solid rgb(145, 158, 171, 0.5) !important;
                background-color: rgb(145, 158, 171, 0.5) !important;
                color: #fff !important;
                cursor: default !important;
            }
        }
    }
}
