#arrow-icon{
  color: white;
}

.text-fill-form{
  cursor: pointer;
}

.text-resume{
  text-align: start;
  padding-left: 10px;
}

.float-right{
  transform: translateY(-8px);
}

.cardtitle {
  font-family: "Roboto-bold";
  font-size: 20px !important;
  padding-right: 20px;
  color: #ffffff;
}
.vert_title {
  font-family: "Roboto-medium";
  letter-spacing: 0px;
  color: #259879;
  opacity: 1;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  @media screen and (max-width: 1600px) {
    font-size: 12px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 10px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px !important;
  }
}
.rad_bord {
  background: #0e103352;
  border: 1px solid #2d2e84;
  border-radius: 14px 14px 0px 0px;
  opacity: 1;
}
.header_ri {
  padding-right: 20px;
}
.small_write {
  font-family: "Roboto-regular" !important;
  font-size: 16px !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
  letter-spacing: 0px !important;
}
.number {
  background: #ffffff;
  border-radius: 2px;
  opacity: 0.1;
  font-family: "Roboto-bold";
  color: #ffffff;
  font-size: 14px;
  opacity: 1;
}
.month {
  background: rgba(15, 103, 217, 0.1);
  border-radius: 2px;
  small {
    padding: 20px;
    text-align: left;
    font-family: "Roboto-regular";
    font-size: 14px;
    letter-spacing: 0px;
    color: #0f67d9;
    opacity: 1;
    line-height: 17px;
  }
}
.month-white {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  small {
    padding: 7px;
    text-align: left;
    font-family: "Roboto-regular";
    font-size: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    line-height: 17px;
  }
}
.past-missioncard {
  background: #efefefe6 0% 0% no-repeat padding-box !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;

  color: #000000;
}
.progress-bar {
  background-color: #27ae89 !important;
}
.col2 {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #db636373 !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  color: #000000;
}
.small_write2 {
  font-family: "Roboto-regular" !important;
  font-size: 13px !important;
  color: #070606 !important;
  opacity: 0.5 !important;
}
.upcom {
  text-align: left;
  font-family: "Roboto-medium";
  font-size: 16px;
  letter-spacing: 0px;
  color: #1d268c;
  opacity: 1;
}
.declined-mission {
  text-align: left;
  font-family: "Roboto-medium";
  font-size: 16px;
  letter-spacing: 0px;
  color: #ce2323;
  opacity: 1;
}
.accepted-mission {
  text-align: left;
  font-family: "Roboto-medium";
  font-size: 16px;
  letter-spacing: 0px;
  color: #2c9e7f;
  opacity: 1;
}
.past-mission {
  text-align: left;
  font-family: "Roboto-medium";
  font-size: 16px;
  letter-spacing: 0px;
  color: #828396;
  opacity: 1;
}
.text-number {
  font-size: 40px !important;
  font-family: "Roboto-bold" !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  opacity: 1;
  @media screen and (max-width: 1600px) {
    font-size: 35px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 25px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 15px !important;
  }
}
.code {
  background: #0f67d9 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
.text-code {
  font-family: "Roboto-regular" !important;
  font-size: 12px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  opacity: 0.71 !important;
}
.text-test {
  font-family: "Roboto" !important;
  font-style: italic !important;
  font-size: 16px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  opacity: 1 !important;
  i {
    margin-left: 10px !important;
    font-size: 11px !important;
  }
}
.box {
  width: 20px;
  height: 20px;
  background: #d52bff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}
.box_1 {
  width: 20px;
  height: 20px;
  background: #4653dd 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}
.text-card-2 {
  font-family: "Roboto-medium";
  font-size: 20px !important;
  opacity: 1;
  color: #ffffff;
  text-align: center !important;
  @media screen and (max-width: 1600px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 10px !important;
  }
}
.current-mision-header {
  font-family: "Roboto-regular";
  font-size: 22px !important;
  opacity: 1;
  color: #ffffff;
  letter-spacing: 0px;
  @media screen and (max-width: 1600px) {
    font-size: 20px !important;
  }
  @media screen and (max-width: 1366px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 700px) {
    font-size: 18px !important;
  }
}
.color-company {
  color: #ffffff !important;
}
.line-height-mission {
  @media screen and (max-width: 1336px) {
    line-height: 30px;
  }
  @media screen and (max-width: 1280px) {
    line-height: 30px;
  }
}
.nav-tabs .nav-link {
  @media screen and (max-width: 1280px) {
    color: rgba(29, 38, 140, 0.5) !important;
    font-family: "Roboto-regular" !important;
    font-size: 18px;
    line-height: 35px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 3rem !important;
  }
}