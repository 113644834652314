#url-input{
    padding: 0 22px 0 2px;
}

.card.card-modal {
    background-color: #ffffff !important;
    border: 1px solid !important;
    border-color: #0f1447 !important;
    border-radius: 11px !important;
    padding: 0 11px 0 11px !important;
}

.text-Label-modal {
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    line-height: 61px !important;
    letter-spacing: 0px !important;
    color: #1c105d !important;
    text-align: left !important;
}

.text-header-modal {
    font-family: "Roboto-bold" !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: 0px !important;
    color: #0f1447 !important;
    text-align: left !important;
}

.text-subheader-modal {
    font-family: "Roboto-regular" !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    color: #0f1447 !important;
    text-align: left !important;
}

.save-button {
    border-radius: 20px !important;
    color: #ffffff;
    font-family: "Roboto-bold" !important;
    font-size: 18px !important;
    padding: 5px 25px !important;
    width: auto !important;
    @media screen and (max-width: 1600px) {
        font-size: 16px !important;
    }
    @media screen and (max-width: 1366px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 1024px) {
        font-size: 12px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 10px !important;
    }
}

.save-button.border-dark {
    border: 1px solid #0f1447 !important;
    color: #0f1447 !important;
    background: #ffffff !important;
}

.save-button.border-white {
    border: 2px solid #ffffff !important;
    background-color: transparent !important;
}

.save-button.border-white {
    border: 2px solid #ffffff !important;
    background-color: transparent !important;
}

.save-button.green {
    background-color: #2c9e7f !important;
    border-color: #2c9e7f !important;
}

.save-button.red {
    background-color: #e22e2e !important;
    border-color: #e22e2e !important;
}

.save-button.blue {
    background-color: #0f1447 !important;
    border-color: #0f1447 !important;
}
.save-button.blue2 {
    background-color: #0f67d9 !important;
    border-color: #0f67d9 !important;
}

.save-button.grey {
    background-color: #d7d8e0 !important;
    border-color: #d7d8e0 !important;
    color: #000000 !important;
}
