.text-title-document {
  font-size: 28px;
  line-height: 34px;
  font-family: "Roboto-medium";
  letter-spacing: 0px;
  color: #1c105d;
  opacity: 1;
}
.border-Col-document {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2a6;
  border-radius: 8px;
  opacity: 1;
  padding: 35px 27px;
  min-height: 500px;
}
.border-col-invoices {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2a6;
  border-radius: 8px;
  opacity: 1;
  padding: 20px 27px;
  min-height: 174px;
  @media screen and (max-width: 1280px) {
    min-height: 209px;;
  }
}
.border-button {
  background: #0f67d9 0% 0% no-repeat padding-box;
  border-radius: 30px !important;
  opacity: 1;
  padding-left: 4% !important;
  padding-right: 4% !important;
}
.text-mission-document {
  font-size: 24px;
  font-family: "Roboto-medium";
  letter-spacing: 0px;
  opacity: 1;
  float: left !important;
  @media screen and (max-width: 620px) {
    font-size: 20px;
  }
  @media screen and (max-width: 425px) {
    font-size: 18px;
  }
}
.margin-buttom-col-candidat {
  margin-bottom: 1.5rem !important;
  @media screen and (max-width: 1280px) {
    margin-bottom: 0.25rem !important;
  }
}
.color-1 {
  color: #d52bff !important;
}
.color-2 {
  color: #4653dd;
}
.text-th-table {
  font-size: 14px;
  font-family: "Roboto-Regular";
  letter-spacing: 0px;
  color: #8689a2 !important;
  opacity: 1;
}
.table.user-table {
  caption-side: bottom;
  border-collapse: inherit;
  color: #8689a2 !important;
  font-size: 14px;
  font-family: "Roboto-Regular";
  opacity: 1;

  th {
    border-bottom: 1px solid #000000a5;
  }

}
.text-font-td {
  font-size: 15px !important;
  font-family: "Roboto-medium" !important;
  letter-spacing: 0px;
  color: #1d268c !important;
  opacity: 1 !important;
}
.border-div-contract {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2a6;
  border-radius: 8px;
  opacity: 1;
  padding: 2% 2% 2% 2% !important;

  .title {
    font-size: 24px !important;
    font-family: "Roboto-medium";
    letter-spacing: 0px;
    color: #0f1447;
    opacity: 1;
  }
  .text {
    font-size: 16px !important;
    letter-spacing: 0px;
    color: #828396 !important;
    opacity: 1;
    text-align: justify !important;
  }
  .button-import {
    background: #0f67d9 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    padding-left: 4%;
    padding-right: 4%;
  }
  margin-right: 3% !important;
}
.display-button-import {
  display: none !important;
}
.text-contract-actif {
  text-decoration: underline;
  font-size: 16px !important;
  font-family: "Roboto-medium";
  letter-spacing: 0px;
  color: #0f67d9 !important;
  opacity: 1;
}
.text-right-active {
  font-size: 16px !important;
  font-family: 25px !important;
  letter-spacing: 0px;
  color: #0f1447;
  opacity: 1;
}
.title-table-contrat {
  font-size: 24px !important;
  font-family: "Roboto-medium";
  letter-spacing: 0px;
  color: #0f1447;
  opacity: 1;
}
.empty-block {
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0px;
  color: #828396 !important;
  opacity: 0.5;
}
.display-row-admin {
  display: flex;
  justify-content: space-between;
  .col-lg-6 {
    flex: 0 0 auto;
    width: 49% !important;
    @media screen and (max-width:900px) {
      width: 100% !important;
    }
  }
}
.line-height-document {
  @media screen and (max-width: 1280px) {
    line-height: 21px !important;
  }
}
.padding-top-bottom-td {
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}
.margin-top-bottom-contrat {
  margin-top: 3rem !important;
  margin-bottom: 5rem !important;
  @media screen and (max-width: 1280px) {
    margin-top: 2rem !important;
    margin-bottom: 4rem !important;
  }
}
.margint-top-user {
  margin-top: 1.25rem !important;
  @media screen and (max-width: 1280px) {
    margin-top: 1rem !important;
  }
}

#doc-perso{
  width: 100% !important;
}

#doc-col .table-responsive-xl {
  @media screen and (max-width: 1280px) {
    width: 100%;
  }

}