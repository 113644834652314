.pr-form {
    padding-right: 3% !important;
}
.pl-form {
    padding-left: 3% !important;
    @media screen and (max-width: 1280px) {
        padding-left: 0% !important;
    }
}
.pr-center-dashboard {
    padding-right: 1% !important;
}
.pl-center-dashboard {
    padding-left: 1% !important;
}
.pr-dashboard {
    padding-right: 2% !important;
}
.pl-dashboard {
    padding-left: 2% !important;
}
.padding-propos4-text {
    padding-top: 5%;
    @media screen and (max-width: 900px) {
        padding-top: 81% !important;
    }
}
.padding-propos1-title {
    padding-top: 7%;
}
.padding-contact1 {
    padding-top: 7%;
}
.padding-0 {
    padding-bottom: 0;
    padding-top: 0;
}
.padding-right-10 {
    padding-right: 10px;
}
.interviews1 {
    padding: 20px 20px !important;
}
.padding_small {
    padding-left: 3px !important;
    padding-right: 3px !important;
}
.padding-left-10 {
    padding-left: 20px;
}
.p-45 {
    padding: 45px !important;
}
.padding-button-inscrit {
    padding-top: 4rem;
}
.p-button {
    padding: 11px !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
}
.padding-10 {
    padding-top: 10%;
}
.padding-9 {
    padding-top: 4%;
}
.p-18 {
    padding: 18% !important;
}
.pt-40 {
    padding-top: 40px;
}
.padding-top-title {
    padding-top: 7rem;
    @media screen and (max-width: 900px) {
        padding-top: 0rem !important;
    }
}
.margin-top-block {
    @media screen and (max-width: 700px) {
        margin-top: 4% !important;
    }
}
