/* import files SCSS */
@import "./custom.scss";
@import "./icons.scss";
@import "./font.scss";
@import "./modal.scss";
@import "./form.scss";
@import "./animate.scss";
@import "./tabulation.scss";
@import "./margin.scss";
@import "./padding.scss";

html {
    scroll-behavior: smooth;
    overflow: auto !important;
}
