.color-title {
    color: #036183 !important;
}

.cookies-content {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #495057;
}
.btn-cookies {
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px !important;
    color: #ffffff;
    border-color: #ffc937 !important;
    background-color: #ffc937 !important;
    @media screen and (max-width: 1440px) {
        font-size: 15px !important;
    }
}

.btn-refuse-cookies {
    font-size: 18px !important;
    @media screen and (max-width: 1440px) {
        font-size: 15px !important;
    }
}

.EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__container {
    height: 18% !important;
}

.EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__overlay {
    display: none !important;
}

.w-content-cookies {
    width: 85% !important;
}
