@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

// Animation
@keyframes infinitScroller {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 30));
  }
}

.slider {
  .slide-track {
    display: flex;
    width: calc(250px * 30);
    .slide {
      animation: infinitScroller 80s linear infinite;
      margin-right: 50px !important;
      font-size: 25px !important;
      text-transform: uppercase !important;
      opacity: 0.5 !important;
    }
    @media screen and (max-width: 466px){
      .slide{
        font-size: 18px !important;
      }
    }
  }
}
