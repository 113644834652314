/* Tabs Style Start */
.nav-tabs {
    border-bottom: 1px solid #dddde9 !important;
    .nav-link {
        color: rgb(29, 38, 140, 0.5) !important;
        font-family: "Roboto-regular" !important;
        font-size: 18px;
        line-height: 35px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-right: 4rem !important;
        @media screen and (max-width: 1600px) {
            font-size: 17px !important;
            line-height: 34px;
        }
        @media screen and (max-width: 1366px) {
            font-size: 16px !important;
            line-height: 33px;
        }
        @media screen and (max-width: 1024px) {
            font-size: 15px !important;
            line-height: 32px;
        }
        @media screen and (max-width: 900px) {
            font-size: 14px !important;
            line-height: 31px;
        }
    }
    .nav-link.active {
        border-bottom: 1px solid #1d268c !important;
        background-color: transparent !important;
        border: none;
        color: rgb(29, 38, 140, 1) !important;
        font-family: "Roboto-medium" !important;
    }
}

.nav-tabs .nav-link {
    border: none !important;
}
/* Tabs Style End */
