.termsLegale {
  min-height: 80vh;

  .stars {
    top: 50% !important;
  }

  .padding-icon {
    padding-top: 4% !important;
  }

  @media screen and (max-width: 1400px) {
    min-height: 70vh;
    .stars {
      top: 40% !important;
    }
  }
  @media screen and (max-width: 1024px) {
    min-height: 50vh;
    .stars {
      top: 20% !important;
    }
  }
}

.conditionsLegale {
  margin-top: -3px;
  margin-bottom: -2px;
  min-height: 97vh;

  @media screen and (max-width: 1600px) {
    min-height: 90vh;
  }
  @media screen and (max-width: 1400px) {
    min-height: 85vh;
  }
  @media screen and (max-width: 1280px) {
    min-height: 84vh;
  }
  @media screen and (max-width: 1024px) {
    min-height: 75vh;
  }
  @media screen and (max-width: 768px) {
    min-height: 70vh;
    padding-bottom: 3%;
  }
  @media screen and (max-width: 620px) {
    min-height: 75vh;
  }
  @media screen and (max-width: 425px) {
    min-height: 85vh;
  }
}

.conditions-auto-page {
  min-height: auto !important;
  padding: 5% 0 !important;
  padding-bottom: 0 !important;
}

.conditionslegale-page1 {
  min-height: 108vh;

  @media screen and (max-width: 1600px) {
    min-height: 92vh;
  }
  @media screen and (max-width: 1400px) {
    min-height: 95vh;
  }
  @media screen and (max-width: 1280px) {
    min-height: 84vh;
  }
  @media screen and (max-width: 1024px) {
    min-height: 75vh;
  }
  @media screen and (max-width: 768px) {
    min-height: 70vh;
    padding-bottom: 3%;
  }
  @media screen and (max-width: 620px) {
    min-height: 75vh;
  }
  @media screen and (max-width: 425px) {
    min-height: 85vh;
  }
}
.conditionslegale-page2 {
  margin-top: -3px;
  margin-bottom: -2px;
  min-height: 130vh;
  position: relative;
  z-index: 2;
  .groupe-legal {
    bottom: -0.5%;
    position: absolute;
    width: 106%;
    left: -1.6%;
    background-size: cover;
  }
  @media screen and (max-width: 1600px) {
    min-height: 118vh;
    .groupe-legal {
      left: -2%;
    }
  }
  @media screen and (max-width: 1400px) {
    min-height: 100vh;
  }
  @media screen and (max-width: 1280px) {
    min-height: 120vh;
    .groupe-legal {
      background-size: unset;
      width: 104%;
      left: -2%;
      height: 31%;
    }
  }
  @media screen and (max-width: 1024px) {
    min-height: 138vh;

    .groupe-legal {
      background-size: unset;
      height: 34%;
      bottom: -7%;
      width: 104%;
      left: -3%;
    }
  }
  @media screen and (max-width: 768px) {
    min-height: 100vh;
    .groupe-legal {
      bottom: -11.6%;
    }
  }
  @media screen and (max-width: 620px) {
    min-height: 110vh;
    .groupe-legal {
      width: 106%;
      left: -6%;
      bottom: -10.6%;
      height: 38%;
    }
  }
  @media screen and (max-width: 425px) {
    min-height: 135vh;
    .groupe-legal {
      width: 107%;
      left: -7%;
      bottom: -14%;
      height: 36%;
    }
  }

  @media screen and (max-width: 375px) {
    min-height: 175vh;
    .groupe-legal {
      width: 108%;
      left: -8%;
      bottom: -22%;
      height: 40%;
    }
  }
  @media screen and (max-width: 320px) {
    min-height: 125vh;
    .groupe-legal {
      width: 109%;
      left: -9%;
      height: 38%;
    }
  }
}

.pg-terms-legale {
  z-index: 1;
  @media screen and (max-width: 768px) {
    padding: 10% 13% 10% 10% !important;
    margin-top: -1%;
    min-height: 57vh;
    .row {
      transform: translate(0px, 20%);
    }
  }
  @media screen and (max-width: 620px) {
    min-height: 57vh;
    padding: 20% 13% 20% 10% !important;
  }
  @media screen and (max-width: 425px) {
    min-height: 57vh;
    padding: 20% 13% 30% 10% !important;
  }
  @media screen and (max-width: 375px) {
    min-height: 57vh;
    padding: 20% 13% 40% 10% !important;
  }
}

.margin-section {
  padding-bottom: 20px;
}
