#navbarScroll {
    justify-content: flex-end;
    margin-top: -2%;
    a {
        color: white;
        padding: 8px 30px 5px 30px !important;
        font-size: 18px !important;
        @media screen and (max-width: 1600px) {
            font-size: 16px !important;
            padding: 8px 25px 5px 25px !important;
        }
        @media screen and (max-width: 1366px) {
            font-size: 14px !important;
            padding: 8px 20px 5px 20px !important;
        }
        @media screen and (max-width: 1150px) {
            font-size: 12px !important;
            padding: 8px 15px 5px 15px !important;
        }
        @media screen and (max-width: 900px) {
            font-size: 18px !important;
            line-height: 22px !important;
            padding: 40px 10px 5px 0px !important;
        }
    }
    a:first-child {
        @media screen and (max-width: 900px) {
            font-size: 18px !important;
            line-height: 22px !important;
            padding: 77px 10px 5px 0px !important;
        }
    }
    a:nth-last-child(2) {
        @media screen and (max-width: 900px) {
            font-size: 18px !important;
            line-height: 22px !important;
            padding: 21px 10px 20px 0px !important;
        }
    }
    a:nth-last-child(1) {
        @media screen and (max-width: 900px) {
            font-size: 18px !important;
            line-height: 22px !important;
            padding: 70px 10px 5px 0px !important;
        }
    }
}
.langue-icon {
    width: 27px !important;
}
.header {
    padding: 2% 4% 2% 4% !important;
    .nav-link {
        color: #ffffff !important;
        font-family: "Roboto-regular" !important;
        line-height: 19px !important;
        font-size: 18px !important;
        transition: transform 0.3s;
    }
    .nav-link:hover {
        transform: scale(1.1);
    }
    .nav-link.btn-login {
        background-color: #3a3ece !important;
        border-radius: 21px !important;
    }
    @media screen and (max-width: 1290px) {
        font-size: 16px !important;
    }
    @media screen and (max-width: 900px) {
        font-size: 10px !important;
    }
}
.login-mobile {
    @media screen and (max-width: 700px) {
        text-align: center !important;
        margin-top: 15% !important;
        padding-top: 3px !important;
        padding-bottom: 3px !important;
        margin-left: 0% !important;
        margin-bottom: 5% !important;
    }
}
.mobile-padding-link {
    @media screen and (max-width: 900px) {
        padding-top: 40px !important;
        padding-left: 0px !important;
    }
}
.login-text::before {
    content: "Connexion";
}
.login-text1::before {
    content: "Mon espace";
}

#flag-icon {
    @media screen and (max-width: 900px) {
        transform: translateY(-200%) translateX(0%) !important;
    }
}

#link-lang{
    @media screen and (max-width: 900px) {
    width: 200px;
    transform: translateX(25px);
    }
}